import React from "react";

import { ardiIcon } from "../../tools";

import QRCode from "react-qr-code";

function LaunchQuest({quest, booking, setQuestStarted, token}) {

    const joinLink = `${window.location.origin}/play/${token}`

    return (
        <div className="CountDown">
        {ardiIcon(quest.category.primary_color,"108","140","0 0 27 35")}
        <div>
            <h2 className="CountDownText">{quest.category.title}</h2>
            <h3 className="CountDownText">{quest.title}</h3>
        </div>
        <div>
            {/* <h3 className="CountDownText">Your Quest Is Ready!</h3> */}
            <br/>
            <h5 className="CountDownText">Share this link with your players:</h5>
            <p className="CountDownText">{joinLink}</p>
            <h5 className="CountDownText">Or they can scan the QR code:</h5>
            <QRCode
                value={joinLink}
                size={100}
                className="QRCode"
            />
        </div>
        <br/>
        <h5 className="CountDownText">Once your team is ready, click the button below to begin.</h5>
        <button
            onClick={() => setQuestStarted(true)}
            className="LaunchQuestButton"
            style={{color: quest.category.primary_color, border: `3px solid ${quest.category.secondary_color}`}}
            >Launch Quest
        </button>
    </div>
    )
}

export default LaunchQuest