import React, { useEffect, useState } from "react";
// import QuestTimer from "./QuestTimer";

function Header({timeUp, questDuration, questLength, longestPathToEnd, setQuestEndState, questEndState}) {

    // Progress Bar---------------------------------
    function updateProgressBar() {
        const stepsRemaining = (questLength - longestPathToEnd);
        let progress = (stepsRemaining + 1) / questLength * 100;

        if (stepsRemaining === questLength) progress = 0;

        if (progress > 100) progress = 100;
    
        const progressBar = document.getElementById('ProgressBar');
        progressBar.style.width = `${progress}%`;
    }

    useEffect(() => {
        updateProgressBar();
    }, [longestPathToEnd]);
    // --------------------------------------------

    // Timer Bar---------------------------------------------------------------------------------
    function updateTimerBar() {
        const now = Date.now();
        const targetTime = new Date(timeUp).getTime()
        const remainingTime = targetTime - now;
        const questDurationInMillis = parseInt(questDuration, 10) * 60 * 1000;
        const percentTimeRemaining = remainingTime / questDurationInMillis * 100;

        const intPercent = Math.floor(Math.max(0, percentTimeRemaining));
        // console.log(intPercent);
        let red = ((1 - intPercent / 100) * 255);
        let green = 100
        let blue = ((255 / 100) * intPercent);
        if (intPercent > 50) {
            blue = 255
        } else {
            red = 255
        }
        if (intPercent < 20) green = 0

        const color = `rgb(${red}, ${green}, ${blue})`;

        if (intPercent < 10){
            let barRed = 255 - intPercent * 2.55
            let barGreen = 240 - (240 - (24 * intPercent))
            let barBlue = 240 - (240 - (24 * intPercent))
            let barColor = `rgb(${barRed}, ${barGreen}, ${barBlue})`
            const TimerBarContainer = document.getElementById('TimerBarContainer');
            TimerBarContainer.style.backgroundColor = barColor
        }

        const timerBar = document.getElementById('TimerBar');
        timerBar.style.width = `${Math.max(0, percentTimeRemaining)}%`;
        timerBar.style.backgroundColor = color;
    }

    useEffect(() => {
        const targetTime = new Date(timeUp).getTime();
        const intervalId = setInterval(() => {
            const now = Date.now();
            const remainingTime = targetTime - now;

            if (remainingTime >= 0) {
                updateTimerBar();
            } else {
                clearInterval(intervalId);
                if (!questEndState){
                    setQuestEndState("timeUp");
                }
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeUp]);
    // ------------------------------------------------------------------------


    return (
        <div className="QuestClientHeader">
            <div className="QuestClientHeaderLeft">
                <h5>Progress:</h5>
                <h5>Time:</h5>
            </div>
            <div className="QuestClientHeaderRight">
                <div id="ProgressBarContainer">
                    <div id="ProgressBar"/>
                </div>
                <div id="TimerBarContainer">
                    <div id="TimerBar"/>
                </div>
            </div>
        </div>
    );
}

export default Header;