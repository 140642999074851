import React, { useState, useEffect } from 'react';
import './CountdownTimer.css';

const CountdownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const now = new Date();
        const difference = targetDate - now;

        let timeLeft = {};
        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div className="countdown-timer">
            <div className="flap">
                <div className="number">
                    {timeLeft.days !== undefined ? String(timeLeft.days).padStart(2, '0') : '00'}
                </div>
                <div className="label">Days</div>
            </div>
            <div className="flap">
                <div className="number">
                    {String(timeLeft.hours).padStart(2, '0')}
                </div>
                <div className="label">Hours</div>
            </div>
            <div className='dots'>:</div>
            <div className="flap">
                <div className="number">
                    {String(timeLeft.minutes).padStart(2, '0')}
                </div>
                <div className="label">Minutes</div>
            </div>
            <div className='dots'>:</div>
            <div className="flap">
                <div className="number">
                    {String(timeLeft.seconds).padStart(2, '0')}
                </div>
                <div className="label">Seconds</div>
            </div>
        </div>
    );
};

export default CountdownTimer;
