import React from "react";
import QuestCard from "./QuestCard";

function QuestCardDisplay({ quests }) {
  const showQuests = () => {
    return quests.map((quest) => {
      return <QuestCard quest={quest} key={quest.id} />;
    });
  };
  return <div className="QuestCardDisplay">{showQuests()}</div>;
}

export default QuestCardDisplay;
