import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import BookingStepOneForm from "./BookingStepOneForm";

function BookingStepOne({setStep, formData, setFormData, categories, quests, setSelectedQuest, selectedQuest, filteredQuests, setFilteredQuests}) {

    const [missingFields, setMissingFields] = useState([]);

    // console.log(selectedQuest.title);
    // console.log(quests);
    // console.log(formData);

    function nextStep() {
        const requiredFields = ["category", "language", "quest", "players", "date"];
        const missing = [];
        requiredFields.forEach((field) => {
          if (!formData[field]) {
            missing.push(field);
          }
        });
        if (missing.length > 0) {
          setMissingFields(missing);
          return;
        }
        setMissingFields([]);
        setStep(2);
      }
      const checkMissingField = (field) => {
        return missingFields.includes(field) ? "BookingStepMissingField" : "";
      }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    // Update category on first load
    useEffect(() => {
        if (!formData.category && categories.length > 0 && !selectedQuest) {
        setFormData({ ...formData, category: categories[0].id.toString() });
    } else if (!formData.category && categories.length > 0 && selectedQuest) {
        if (selectedQuest.category){
            setFormData({ ...formData, category: selectedQuest.category.id.toString() });
        } else if (categories.length > 0) {
            setFormData({ ...formData, category: categories[0].id.toString() });
        }
    }
    }, [categories, formData, setFormData]);
    // ---------------------------------------------------------

    // Update selected quest on first load
    useEffect(() => {
        const filtered = quests.filter(quest => quest.category.id.toString() === formData.category);

        if (filtered.length > 0 && formData.quest === "") {
            setFormData({
                ...formData,
                quest: filtered[0].id.toString(),
                language: filtered[0].language
            });
        }
    }, [formData.category, quests]);
    // ---------------------------------------------------------

    // Update selectedQuest on change of quest
    useEffect(() => {
        if (formData.quest && quests.length > 0) {
            const findQuest = quests.find(quest => quest.id.toString() === formData.quest);
            if (findQuest) {
                setSelectedQuest(findQuest);
            }
        }
    }, [formData.quest, quests]);
    // ---------------------------------------------------------

    // Update price on change of players or selected quest
    useEffect(() => {
        if (formData.players && selectedQuest.price_per_unit) {
            setFormData({
                ...formData,
                price: (Number(selectedQuest.price_per_unit.amount) * Number(formData.players))
            })
        }
    }, [formData.players, selectedQuest]);
    // ---------------------------------------------------------

    // Update language on change of selected quest
    useEffect(() => {
        if (selectedQuest) {
            setFormData({
                ...formData,
                language: selectedQuest.language,
            })
        }
    }, [selectedQuest]);
    // ---------------------------------------------------------


    return(
        <BookingStepOneForm 
        formData={formData} 
        handleChange={handleChange} 
        checkMissingField={checkMissingField} 
        selectedQuest={selectedQuest}
        setFormData={setFormData}
        nextStep={nextStep}
        categories={categories}
        quests={quests}
        filteredQuests={filteredQuests}
        />
    ) 
}

export default BookingStepOne