import React from "react";
import ExpandableTextBox from "./ExpandableTextBox";

function WhyChooseUs() {
  const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

  const imageLink = `${MEDIA}/media/pointing-brandon-brighter.png`;

  return (
    <div className="WhyChooseUs" id="WhyChooseUs">
      <div className="WhyChooseUsLeft">
        <img src={imageLink} alt="" />
      </div>
      <div className="WhyChooseUsRight">
        <h1>Why Choose Us</h1>
        <ExpandableTextBox
          color="#F2F8E6"
          title="Our Idea"
          content="We believe that everyone deserves easy and affordable access to top-notch adventure games and events, both online and offline. That's why we've created a service that brings people together through group activities, fostering real-life connections and social awareness in a world often dominated by digital."
        />
        <ExpandableTextBox
          color="#E6F1FF"
          title="Vision"
          content="In a society overloaded with online communication, we envision a future where people rediscover the joy of socializing in the real world. By providing simple, technology-driven tools and experiences, we aim to create a global community of adventurers, storytellers, and lifelong learners."
        />
        <ExpandableTextBox
          color="#FEEEE8"
          title="Mission"
          content="Our mission is to increase the level of social interaction and engagement among people by harnessing the power of technology. Through our diverse range of products and services, we strive to create meaningful experiences that challenge, inspire, and entertain, helping individuals forge stronger connections with the world around them."
        />
      </div>
    </div>
  );
}

export default WhyChooseUs;
