import React from 'react';
import Navbar from './Sub-Components/Navbar';
import Footer from './Sub-Components/Footer';

const Layout = ({ children }) => {
    return (
        <div>
            <Navbar />
            {children}
            <Footer />
        </div>
    );
};

export default Layout;