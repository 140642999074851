/*
TODO: Ensure Refactor for Player Interaction complete
|  [x]      - Refactor to move from `captain` only submitting answer to allowing `questers` to submit.
|  [x]      - refactor event_type "answer submitted" to be "captain_as" and "quester_as" to control whos submitting
|  [x]      - "captain_as" will function as old "answer submitted"
|  [x]      - "quester_as" will function to allow display of timed floating notifications
|  [x]      - cache/map for "quester_as" timed floating notifications will be limited to 10
|  [X]      - add playerName to quester notification
|  [X]      - Reorganize code structure with clear category headers
|  [X]      - add better error handling throughout
|  [X]      - add questerNotification
|  [X]      - better use of useCallback
|  [X]      - clean up concerns and breakdown functions
|  []      - Ensure QuestTimer is used properly
|  []      - 
*/

// ************************
// *******  Imports *******
// ************************
import React, { useState, useEffect, useCallback } from "react";
import WaitForCaptain from "./WaitForCaptain";
import QuestTimer from "./Header/QuestTimer";
import EvidenceTab from "./Tabs/EvidenceTab";
import HintsTab from "./Tabs/HintsTab";
import SuggestionTab from "./Tabs/SuggestionTab"
import HistoryTab from "./Tabs/HistoryTab";
import MapTab from "./Tabs/MapTab";
import QuestionsTab from "./Tabs/QuestionsTab";
import QuestTab from "./Tabs/QuestTab";
import Header from "./Header/Header";
import FooterNav from "./FooterNav";
import Outro from "./Outro/Outro";

// ************************
// ****** CONSTANTS ******
// ************************
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const TABS = {
  EVIDENCE: "evidence",
  HINTS: "hints",
  HISTORY: "history",
  MAP: "map",
  QUESTIONS: "questions",
  QUEST: "quest",
  SUGGESTION:"suggestion"
};

function Play({
  quest,
  prompts,
  token,
  playerName,
  playerRole,
  activePromptNumber,
  setActivePromptNumber,
}) {
  // ************************
  // ****** STATE **********
  // ************************
  const [sessionData, setSessionData] = useState(null);
  const [csrfToken, setCsrfToken] = useState(null);
  const [activeTab, setActiveTab] = useState(TABS.QUEST);
  const [questerNotifications, setQuesterNotifications] = useState([]);
  const [answer, setAnswer] = useState("");
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const [previousPrompt, setPreviousPrompt] = useState([]);
  const [newEvidence, setNewEvidence] = useState(false);
  const [newQuestion, setNewQuestion] = useState(false);
  const [hintRequestMessage, setHintRequestMessage] = useState("");
  const [questEndState, setQuestEndState] = useState(null);
  const [showEndPopup, setShowEndPopup] = useState(false);
  const [finalAnswersSubmitted, setFinalAnswersSubmitted] = useState(false);
  const [longestPathToEnd, setLongestPathToEnd] = useState(null);
  const [newSuggestion, setNewSuggestion] = useState(false);
  const [oldSuggestion,setOldSuggestion] = useState(0)
  // ************************
  // ****** UTILITIES ******
  // ************************
  const clean = useCallback((str) => {
    return str
      .replace(/\s+/g, " ")
      .replace(/[.,/#!$%&*;:{}=\-_`~()]/g, "")
      .trim()
      .toLowerCase();
  }, []);

  const calculateLongestPathToEnd = useCallback(() => {
    let visited = new Set();
    let longestPath = 0;

    const depthFirstSearch = (promptNumber, currentLength) => {
      const currentPrompt = prompts[promptNumber];
      if (!currentPrompt) {
        console.error("Prompt not found:", promptNumber);
        return;
      }
      longestPath = Math.max(longestPath, currentLength);

      if (!currentPrompt.next_prompt_ids.length) return;

      for (const num of currentPrompt.next_prompt_ids) {
        if (!visited.has(num)) {
          visited.add(num);
          depthFirstSearch(num, currentLength + 1);
          visited.delete(num);
        }
      }
    };

    visited.add(activePromptNumber);
    depthFirstSearch(activePromptNumber, 1);
    setLongestPathToEnd(longestPath);
  }, [prompts, activePromptNumber, setLongestPathToEnd]);

  // ************************
  // ****** API CALLS ******
  // ************************
  const postQuestEvent = useCallback(
    async (body) => {
      try {
        const response = await fetch(`${API_BASE_URL}/events/${token}/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
          body: JSON.stringify(body),
        });
        await response.json();
      } catch (error) {
        console.error("Error posting quest event:", error);
      }
    },
    [token, csrfToken]
  );

  const patchSessionData = useCallback(
    async (body) => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/quest-session/${token}/`,
          {
            method: "PATCH",
            headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
            },
            body: JSON.stringify(body),
          }
        );
        await response.json();
      } catch (error) {
        console.error("Error patching session data:", error);
      }
    },
    [token, csrfToken]
  );

  // ************************
  // ****** HANDLERS *******
  // ************************
  const handleQuesterNotification = (answer) => {
    if (!playerName) {
      console.warn(`playerName not found for ${token}`);
    }

    setQuesterNotifications((prev) => {
      const newNotification = {
        id: Date.now(),
        answer,
        player: playerName || "Quester",
      };
      const newNotifications = [...prev, newNotification].slice(-10);

      setTimeout(() => {
        setQuesterNotifications((current) =>
          current.filter((n) => n.id !== newNotification.id)
        );
      }, 5000);

      return newNotifications;
    });
  };

const allSuggestionChecked = (suggestion) =>{
  setOldSuggestion(suggestion.length)
}

  const handleHintRequest = () => {
    setHintRequestMessage("");
    const currentPromptHints = prompts[activePromptNumber]?.hints || [];

    if (currentPromptHints.length < 1) {
      setHintRequestMessage("There are no hints available at this stage.");
      return;
    }

    const usedHints = sessionData.events.filter(
      (event) => event.event_type === "hint requested"
    );

    const availableHint = currentPromptHints.find(
      (hint) => !usedHints.some((used) => used.event_data.id === hint.id)
    );

    if (availableHint) {
      postQuestEvent({
        event_type: "hint requested",
        event_data: availableHint,
      });
      return availableHint;
    }

    setHintRequestMessage("There are no more hints available at this stage.");
  };

  const handleSubmitAnswer = async (prompt) => {
    if (!answer) return;

    const eventType = playerRole === "captain" ? "captain_as" : "quester_as";
    const answerAsPerRoll = playerRole === "captain" ? answer : playerName +" suggested "+answer ; 
    await postQuestEvent({ event_type: eventType, event_data: answerAsPerRoll });

    if (playerRole !== "captain") {
      handleQuesterNotification(answer);
      return;
    }

    const newPromptNumber = await findNextPrompt(prompt);

    if (newPromptNumber) {
      await updatePromptState(prompt, newPromptNumber);
    } else {
      setWrongAnswer(true);
    }
  };

  // ************************
  // **** PROMPT LOGIC *****
  // ************************
  const findNextPrompt = (prompt) => {
    const cleanedAnswer = clean(answer);

    if (prompt.required_prompt) {
      return findRequiredPromptMatch(prompt, cleanedAnswer);
    }

    return findNonRequiredPromptMatch(cleanedAnswer);
  };

  const findRequiredPromptMatch = (prompt, cleanedAnswer) => {
    const nonRequiredPromptNumbers = prompts
      .filter((p) => !p.required_prompt)
      .map((p) => p.prompt_number);

    const possibleNextPromptNumbers = [
      ...prompt.next_prompt_ids,
      ...nonRequiredPromptNumbers,
    ];

    return findPromptNumberMatch(possibleNextPromptNumbers, cleanedAnswer);
  };

  const findNonRequiredPromptMatch = (cleanedAnswer) => {
    const blockedPromptNumbers = sessionData.events
      .filter((event) => event.event_type === "block prompt")
      .map((event) => event.event_data);

    const possibleNextPromptNumbers = prompts
      .filter((p) => !blockedPromptNumbers.includes(p.prompt_number))
      .map((p) => p.prompt_number);

    return findPromptNumberMatch(possibleNextPromptNumbers, cleanedAnswer);
  };

  const findPromptNumberMatch = (promptNumbers, cleanedAnswer) => {
    return promptNumbers.find((num) => {
      const prompt = prompts.find((p) => p.prompt_number === num);
      return clean(prompt.previous_prompt_answer) === cleanedAnswer;
    });
  };

  const updatePromptState = async (currentPrompt, newPromptNumber) => {
    setPreviousPrompt(currentPrompt);
    await patchSessionData({ active_prompt: newPromptNumber });
    setActivePromptNumber(newPromptNumber);

    const nextPrompt = prompts.find((p) => p.prompt_number === newPromptNumber);

    if (currentPrompt.required_prompt && nextPrompt?.required_prompt) {
      await Promise.all([
        postQuestEvent({
          event_type: "block prompt",
          event_data: currentPrompt.prompt_number,
        }),
        postQuestEvent({
          event_type: "new prompt reached",
          event_data: nextPrompt,
        }),
      ]);
    }
  };

  // ************************
  // ****** EFFECTS ********
  // ************************

  useEffect(() => {
    const fetchCsrfToken = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/get-csrf-token`);
        const result = await response.json();
        setCsrfToken(result.csrfToken);
      } catch (error) {
        console.error("Error fetching CSRF token:", error);
      }
    };

    if (!csrfToken) {
      fetchCsrfToken();
    }
  }, [csrfToken]);

  useEffect(() => {
    if (prompts && !previousPrompt) {
      setPreviousPrompt(0);
    }
  }, [activePromptNumber, prompts, previousPrompt]);

  useEffect(() => {
    const updateSession = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/quest-session/${token}`);
        const result = await response.json();
        setActivePromptNumber(result.quest_session.active_prompt);
        setSessionData(result.quest_session);
      } catch (error) {
        console.error("Error updating session:", error);
      }
    };

    updateSession();
    const interval = setInterval(updateSession, 4000);
    return () => clearInterval(interval);
  }, [token, setActivePromptNumber]);

  useEffect(() => {
    if (questEndState && playerRole === "captain") {
      const currentPrompt = prompts[activePromptNumber];
      const handleEndState = async () => {
        const endStateEvent = sessionData?.events.find(
          (event) => event.event_type === "end state reached"
        );

        if (!endStateEvent) {
          await postQuestEvent({
            event_type: "end state reached",
            event_data: {
              state: questEndState,
              prompt: currentPrompt.previous_prompt_answer,
              prompt_number: currentPrompt.prompt_number,
            },
          });
        }

        if (!sessionData?.quest_completed_at) {
          await patchSessionData({ quest_completed_at: new Date() });
        }
      };

      if (questEndState && playerRole === "captain") {
        const currentPrompt = prompts[activePromptNumber];
        handleEndState(currentPrompt);
      }
    }
  }, [
    questEndState,
    playerRole,
    prompts,
    activePromptNumber,
    sessionData,
    postQuestEvent,
    patchSessionData,
  ]);

  useEffect(() => {
    if (sessionData?.events) {
      const endStateEvent = sessionData.events.find(
        (event) => event.event_type === "end state reached"
      );
      if (endStateEvent) {
        setQuestEndState(endStateEvent.event_data.state);
      }

      const hasSubmittedAnswers = sessionData.events.some(
        (event) => event.event_type === "final answers submitted"
      );
      if (hasSubmittedAnswers) {
        setFinalAnswersSubmitted(true);
      }
    }
  }, [sessionData]);

  useEffect(() => {
    if (!sessionData || playerRole !== "captain") return;

    const currentPrompt = prompts[activePromptNumber];

    const handlePromptMedia = async () => {
      const mediaTypes = [
        {
          type: "image",
          condition: currentPrompt.add_image_to_evidence,
          value: currentPrompt.image,
        },
        {
          type: "audio",
          condition: currentPrompt.add_audio_to_evidence,
          value: currentPrompt.audio,
        },
        {
          type: "video",
          condition: currentPrompt.add_video_to_evidence,
          value: currentPrompt.video,
        },
      ];

      for (const { type, condition, value } of mediaTypes) {
        if (condition) {
          const eventExists = sessionData.events.some(
            (event) =>
              event.event_data?.[type] === value &&
              event.event_type === "evidence found"
          );

          if (!eventExists) {
            await postQuestEvent({
              event_type: "evidence found",
              event_data: { [type]: value },
            });
            setNewEvidence(true);
          }
        }
      }
    };

    const handleQuestions = async () => {
      if (
        Array.isArray(currentPrompt.end_game_questions) &&
        currentPrompt.end_game_questions.length > 0
      ) {
        const newQuestions = currentPrompt.end_game_questions.filter(
          (question) =>
            !sessionData.events.some(
              (event) =>
                event.event_data?.id === question.id &&
                event.event_type === "question found"
            )
        );

        for (const question of newQuestions) {
          await postQuestEvent({
            event_type: "question found",
            event_data: question,
          });
        }

        if (newQuestions.length > 0) {
          setNewQuestion(true);
        }
      }
    };

    const handleSuggestion = async () => {
      let newsuggestion =   sessionData.events.filter(event => event.event_type === "quester_as" );

      if (newsuggestion.length > oldSuggestion) {
        setNewSuggestion(true);
      } else {
        setNewSuggestion(false);

      }
    };

    const handleEvidence = async () => {
      if (currentPrompt?.evidence?.length > 0) {
        const newEvidence = currentPrompt.evidence.filter(
          (item) =>
            !sessionData.events.some((event) => event.event_data.id === item.id)
        );

        for (const evidence of newEvidence) {
          await postQuestEvent({
            event_type: "evidence found",
            event_data: evidence,
          });
        }

        if (newEvidence.length > 0) {
          setNewEvidence(true);
        }
      }
    };

    const init = async () => {
      if (currentPrompt.required_prompt) {
        calculateLongestPathToEnd();
      }

      setHintRequestMessage("");

      await Promise.all([
        handlePromptMedia(),
        handleQuestions(),
        handleEvidence(),
        handleSuggestion(),
      ]);

      if (currentPrompt.trigger_quest_end_success) {
        setQuestEndState("success");
      } else if (currentPrompt.trigger_quest_end_failed) {
        setQuestEndState("failed");
      }
    };

    init();
  }, [
    activePromptNumber,
    calculateLongestPathToEnd,
    playerRole,
    postQuestEvent,
    prompts,
    sessionData,
  ]);

  useEffect(() => {
    setWrongAnswer(false);
  }, [answer]);

  // ************************
  // ****** RENDER *********
  // ************************
  if (!sessionData) {
    return (
      <div className="QuestRoomContent Centered">
        <h2>Loading...</h2>
      </div>
    );
  }

  if (!sessionData.quest_started) {
    return (
      <div className="QuestRoomContent Centered">
        <WaitForCaptain
          role={playerRole}
          token={token}
          quest={quest}
          setSessionData={setSessionData}
          csrfToken={csrfToken}
        />
      </div>
    );
  }

  if (finalAnswersSubmitted) {
    return (
      <div className="QuestRoomContent Centered">
        <Outro sessionData={sessionData} quest={quest} />
      </div>
    );
  }

  const currentPrompt = prompts[activePromptNumber];

  const renderTab = () => {
    const tabs = {
      [TABS.EVIDENCE]: () => (
        <EvidenceTab
          sessionData={sessionData}
          setNewEvidence={setNewEvidence}
        />
      ),
      [TABS.HINTS]: () => (
        <HintsTab
          prompt={currentPrompt}
          playerRole={playerRole}
          handleHintRequest={handleHintRequest}
          sessionData={sessionData}
          hintRequestMessage={hintRequestMessage}
        />
      ),
      [TABS.SUGGESTION]: () => (
        <SuggestionTab
          prompt={currentPrompt}
          allSuggestionChecked={allSuggestionChecked}
          sessionData={sessionData}
        />
      ),
      [TABS.HISTORY]: () => <HistoryTab sessionData={sessionData} />,
      [TABS.MAP]: () => (
        <MapTab
          quest={quest}
          prompt={currentPrompt}
          previousPrompt={previousPrompt}
        />
      ),
      [TABS.QUESTIONS]: () => (
        <QuestionsTab
          sessionData={sessionData}
          setNewQuestion={setNewQuestion}
          postQuestEvent={postQuestEvent}
          questEndState={questEndState}
        />
      ),
      [TABS.QUEST]: () => (
        <QuestTab
          questEndState={questEndState}
          wrongAnswer={wrongAnswer}
          playerRole={playerRole}
          prompt={currentPrompt}
          setAnswer={setAnswer}
          answer={answer}
          handleSubmitAnswer={handleSubmitAnswer}
          setActiveTab={setActiveTab}
          showEndPopup={showEndPopup}
          setShowEndPopup={setShowEndPopup}
          quest={quest}
          sessionData={sessionData}
        />
      ),
    };

    return (tabs[activeTab] || (() => <div>Invalid tab selected</div>))();
  };

  return (
    <div className="QuestRoomContent" style={{ position: "relative" }}>
      {!questEndState && (
        <Header
          timeUp={sessionData.quest_deadline}
          questLength={sessionData.longest_path}
          longestPathToEnd={longestPathToEnd}
          questDuration={quest.duration}
          questEndState={questEndState}
          setQuestEndState={setQuestEndState}
        />
      )}

      {questerNotifications.map((notification, index) => (
        <div
          key={index}
          className="floating-notification"
          style={{
            position: "fixed",
            top: `${20 + index * 60}px`,
            right: "20px",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            color: "white",
            padding: "10px",
            borderRadius: "5px",
            zIndex: 1000,
          }}
        >
          {notification.player} suggested: {notification.answer}
        </div>
      ))}

      {renderTab()}

      <FooterNav
        setActiveTab={setActiveTab}
        quest={quest}
        newEvidence={newEvidence}
        newQuestion={newQuestion}
        playerRole={playerRole}
        newSuggestions = {newSuggestion}
      />
    </div>
  );
}

export default Play;
