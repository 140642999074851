import React, { useState, useEffect } from "react";

function SuggestionTab({prompt, allSuggestionChecked, sessionData}) {
   
    const [suggetionEvents, setSuggestionEvents] = useState([]);
   

   

    useEffect(() => {
        if(sessionData?.events){
            const suggestion = sessionData.events.filter(event => event.event_type === "quester_as" );
            setSuggestionEvents(suggestion);
            allSuggestionChecked(suggestion)
        }
    }, [sessionData]);

  
    const renderSuggestions = () => {
        return suggetionEvents.map((suggestionEvent) => (
            <div className="SuggestionsTabContainerItem" key={suggestionEvent.id}>
                <p>{suggestionEvent.event_data}</p>
            </div>
        ))
    }
    return (
        <div className="SuggestionsTab">
            
            <div className="SuggestionsContainer">
              
                {renderSuggestions()}
            </div>
        </div>
    );
}

export default SuggestionTab;