import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import CategoryBanner from "./Sub-Components/CategoryBanner";
import WhatIsThisCategory from "./Sub-Components/WhatIsThisCategory";
import QuestCardDisplay from "./Sub-Components/QuestCardDisplay";
import WhatCustomersSay from "./Sub-Components/WhatCustomersSay";
import FlagSelector from "./Sub-Components/FlagSelector";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function CategoryOverview() {
  const { type } = useParams();
  const [category, setCategory] = useState([]);
  const [quests, setQuests] = useState([]);
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState("en");

  const [questsByLanguage, setQuestsByLanguage] = useState([]);

  useEffect(() => {
    const filteredQuests = quests.filter(
      (quest) => quest.language === language
    );
    setQuestsByLanguage(filteredQuests);
  }, [quests, language]);

  const WhatCustomersSayRef = useRef(null);

  const scrollToWhatCustomersSay = () => {
    if (WhatCustomersSayRef.current) {
      WhatCustomersSayRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchCategoryAndQuests = async () => {
      try {
        const categoryRes = await fetch(`${API_BASE_URL}/categories/${type}`);
        if (!categoryRes.ok) {
          throw new Error(`HTTP error! Status: ${categoryRes.status}`);
        }
        const categoryData = await categoryRes.json();
        if (isMounted) {
          setCategory(categoryData);
        }
        const questsRes = await fetch(
          `${API_BASE_URL}/categories/${type}/quests`
        );
        if (!questsRes.ok) {
          throw new Error(`HTTP error! Status: ${questsRes.status}`);
        }
        const questsData = await questsRes.json();
        if (isMounted) {
          setQuests(questsData);
        }
      } catch (err) {
        // console.error("Fetch error:", err);
        if (isMounted) {
          setError(err);
        }
      }
    };
    fetchCategoryAndQuests();
    return () => {
      isMounted = false;
    };
  }, [type]);

  return (
    <div className="LimitWidthParent">
      <div className="LimitWidthChild">
        <CategoryBanner
          category={category}
          scrollToWhatCustomersSay={scrollToWhatCustomersSay}
        />
        <WhatIsThisCategory category={category} quests={quests} />

        <FlagSelector language={language} setLanguage={setLanguage} />
        <QuestCardDisplay quests={questsByLanguage} />
        <div ref={WhatCustomersSayRef}>
          <WhatCustomersSay category={category} />
        </div>
      </div>
    </div>
  );
}

export default CategoryOverview;
