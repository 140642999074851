import React from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import EndStatePopup from "../EndStatePopup";

function QuestTab({prompt, setAnswer, handleSubmitAnswer, answer, playerRole, wrongAnswer, setActiveTab, showEndPopup, setShowEndPopup, quest, sessionData, questEndState}) {

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

    return (
        <div className="QuestTab">
            {showEndPopup && <EndStatePopup questEndState={questEndState} setShowEndPopup={setShowEndPopup} setActiveTab={setActiveTab} quest={quest} sessionData={sessionData}/>}
            {!questEndState && 
                <div className="QuestTabAnswer">
                    <input placeholder="Enter text" className={wrongAnswer ? "QuestTabInputWrongAnswer" : ""} type="text" value={answer} onChange={(e) => setAnswer(e.target.value)}/>
                    <button onClick={() => handleSubmitAnswer(prompt)}> {playerRole === "captain"?"Submit":"Suggest"}</button>
                </div>
            }
            {questEndState && <button className="QuestTabEndPopupButton" onClick={() => setShowEndPopup(true)}>Continue</button>}
            <p>{prompt.question}</p>
            <h4 style={{margin: "0 0 8px 0"}} className="QuestTabTitle">{prompt.previous_prompt_answer}</h4>
            <p className="QuestTabText" dangerouslySetInnerHTML={{ __html: prompt.text_rendered }}/>
            {prompt.image && <img className="QuestTabImage" src={`${MEDIA}${prompt.image}`} alt="Place Holder"/>}

            <AudioPlayer
            src={`${MEDIA}${prompt.audio}`}
            // onPlay={(e) => console.log("onPlay")}
            autoPlay={false}
            layout="horizontal"
            className="QuestTabAudioPlayer"
            />
        </div>
    );
}

export default QuestTab;