import React, { useState, useEffect } from "react";

import QuestionsSummary from "./QuestionsSummary";
import LeaderBoard from "./LeaderBoard";
import LeaveReview from "./LeaveReview";
import ShareYourSession from "./ShareYourSession";
import QuestOutro from "./QuestOutro";
import EndQuestBonus from "./EndQuestBonus";

function Outro({sessionData, quest}) {
    const [step, setStep] = useState(1);

    useEffect(() => {
        const updateLeaderboard = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/update-leaderboard`);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
            } catch (err) {
                console.error("Failed to update leaderboard:", err);
            }
        }
        updateLeaderboard();
    }, []); 

    const handleNavClick = (direction) => {
        if (direction === "next" && step < 4) {
            setStep(step + 1);
        } else if (direction === "back" && step > 1) {
            setStep(step - 1);
        }
    }

    switch (step) {
        case 1:
            return <QuestionsSummary sessionData={sessionData} handleNavClick={handleNavClick}/>;
        case 2:
            return <LeaderBoard sessionData={sessionData} handleNavClick={handleNavClick} quest={quest}/>;
        // case 3:
        //     return <LeaveReview sessionData={sessionData} handleNavClick={handleNavClick}/>;
        // case 4:
        //     return <ShareYourSession sessionData={sessionData} handleNavClick={handleNavClick}/>;
        case 3:
            return <QuestOutro sessionData={sessionData} handleNavClick={handleNavClick} quest={quest}/>;
        case 4:
            return <EndQuestBonus sessionData={sessionData} handleNavClick={handleNavClick} quest={quest}/>;
        default:
            return null;
    }
}

export default Outro;