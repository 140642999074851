import React from "react";

const FlagSelector = ({ language, setLanguage }) => {
  const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

  const flags = [
    { code: 'en', name: 'English', path: '/media/american-flag.png' },
    { code: 'se', name: 'Swedish', path: '/media/swedish-flag.png' },
    { code: 'fr', name: 'French', path: '/media/french-flag.png' },
    { code: 'ru', name: 'Russian', path: '/media/russian-flag.png' },
    { code: 'es', name: 'Spanish', path: '/media/spanish-flag.png' }
  ];

  const handleFlagClick = (code) => {
    setLanguage(code === language ? '' : code);
  };

  return (
    <div className="inline-flex items-center space-x-4 p-4 rounded-lg bg-white/5">
      <div className="flex items-center space-x-4">
        <h6 className="text-sm font-medium text-gray-700">
          Filter by Language
        </h6>
        <span className="text-gray-400">&rarr;</span>
        <div className="flex items-center space-x-2 mb-30  logo-filter">
          {flags.map(({ code, name, path }) => (
            <button
              key={code}
              onClick={() => handleFlagClick(code)}
              className={`relative group transition-transform duration-200 hover:scale-110 ${
                language === code ? 'ring-2 ring-blue-500 ring-opacity-50' : ''
              }`}
              title={name}
            >
              <img
                src={`${MEDIA}${path}`}
                alt={`${name} flag`}
                className={`w-8 h-6 object-cover rounded ${
                  language === code ? 
                  'opacity-100' : 
                  'opacity-70 hover:opacity-100'
                }`}
              />
              <span className="sr-only">Select {name}</span>
              {language === code && (
                <span className="absolute -bottom-1 left-1/2 transform -translate-x-1/2 w-1.5 h-1.5 bg-blue-500 rounded-full" />
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FlagSelector;