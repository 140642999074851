import React from "react";
import { useNavigate } from "react-router-dom";
function QuestCard({ quest }) {
  const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

  const imageLink = `${MEDIA}${quest.card_image}`;
  const iconLink = `${MEDIA}${quest.icon}`;
  const navigate = useNavigate();
  // console.log(quest)

  return (
    <div
      onClick={() => navigate(`/quests/info/${quest.id}`)}
      className="QuestCard"
    >
      <a style={{ textDecoration: "none" }} href={`/quests/info/${quest.id}`}>
        <div
          style={{
            backgroundColor: quest.category.primary_color,
            backgroundImage: `url(${imageLink})`,
            border: `5px solid ${quest.category.secondary_color}`,
          }}
          className="QuestCardImage"
        >
          <img
            alt=""
            className="QuestCardBackground"
            src={imageLink}
            style={{ border: `5px solid ${quest.category.secondary_color}` }}
          />
          <div className="QuestCardLocationContainer">
            {quest.city ? (
              <div className="QuestCardLocation">{quest.city}</div>
            ) : (
              <div></div>
            )}
            {quest.icon ? (
              <img
                alt=""
                style={{
                  backgroundColor: quest.category.primary_color,
                  borderRadius: "50%",
                  padding: "10px",
                }}
                className="QuestCardIcon"
                src={iconLink}
              />
            ) : (
              <div></div>
            )}
          </div>
          <div className="QuestCardTitle">{quest.title}</div>
          {/* <div className="QuestCardSubtitle">{quest.quest_script__episode > 0 ? `Number of episodes: ${quest.episodes}` : `Single Quest`}</div> */}
          <div className="QuestCardSubtitle">
            {quest.duration} minute - Quest
          </div>
        </div>
      </a>
      <div className="QuestCardBody">
        <div
          className="QuestCardDescription"
          dangerouslySetInnerHTML={{ __html: quest.short_description_rendered }}
        />
        {/* <div className="QuestCardAvailability">
          Available in: &nbsp;
          {quest.language.includes('en') ? <img className="QuestCardFlag" alt="American Flag" src={`${MEDIA}/media/american-flag.png`}/> : null}
          {quest.language.includes('se') ? <img className="QuestCardFlag" alt="Swedish Flag" src={`${MEDIA}/media/swedish-flag.png`}/> : null}
          {quest.language.includes('fr') ? <img className="QuestCardFlag" alt="French Flag" src={`${MEDIA}/media/french-flag.png`}/> : null}
          {quest.language.includes('ru') ? <img className="QuestCardFlag" alt="Russian Flag" src={`${MEDIA}/media/russian-flag.png`}/> : null}
          {quest.language.includes('es') ? <img className="QuestCardFlag" alt="Spanish Flag" src={`${MEDIA}/media/spanish-flag.png`}/> : null}
        </div> */}

        {/* <div className="QuestCardPrice">${quest.price_per_unit} per user</div> */}
      </div>
      <div className="QuestCardbtns">
        <a href={`/quests/info/${quest.id}`}>
          <button
            style={{ backgroundColor: quest.category.primary_color }}
            className="QuestCardButton"
          >
            Read More
          </button>
        </a>

        <a href={`/booking/q${quest.id}`}>
          <button
            style={{ backgroundColor: quest.category.primary_color }}
            className="QuestCardButton"
          >
            Book Now
          </button>
        </a>
      </div>
    </div>
  );
}

export default QuestCard;
