import React from "react";
import Map from "../Map";

function MapTab(prompt, previousPrompt, quest) {
    const origin = previousPrompt?.waypoints && previousPrompt.waypoints.length > 0 
    ? [previousPrompt.waypoints[0].lat, previousPrompt.waypoints[0].long] 
    : null
    const destination = prompt.waypoints && prompt.waypoints.length > 0 
    ? [prompt.waypoints[0].lat, prompt.waypoints[0].long] 
    : null
 

    return (
        <div className="MapTab">
            <Map origin={origin} destination={destination} quest={quest} />
        </div>
    );
}

export default MapTab;