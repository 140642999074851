import React, { useState } from "react";

function ContactUs({ color }) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [formStatus, setFormStatus] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("https://sender.ardi.one", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        message: message,
        locale: "en",
        app: "ARDI",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setFormStatus("success");
        // console.log(data);
        setName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        setFormStatus("error");
        // console.error('Error:', error);
      });
  };

  return (
    <div style={{ backgroundColor: color }} className="ContactUs">
      <h1>Contact Us</h1>
      <hr />
      <div className="ContactUsFormUpper">
        <div className="ContactUsFormField">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="ContactUsFormField">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className="ContactUsFormMessage">
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
      </div>
      <div className="ContactUsButtonContainer">
        <button type="submit" onClick={handleSubmit}>
          Send
        </button>
      </div>
    </div>
  );
}

export default ContactUs;
