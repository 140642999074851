import React from "react";
import { useNavigate } from "react-router-dom";

function EndQuestBonus({sessionData, handleNavClick, quest}) {

    const navigate = useNavigate();

    return (
        <div className="OutroStepContainer">
            <p dangerouslySetInnerHTML={{ __html: quest.end_of_quest_bonus_rendered }}/>
            <div className="OutroTabNavButtons">
                <button className="OutroTabNavButton" onClick={() => handleNavClick("back")}>Back</button>
                <button className="OutroTabNavButton" onClick={() => navigate("/")}>End Quest</button>
            </div>
        </div>
    );
}

export default EndQuestBonus;