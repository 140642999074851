import React from "react";

function QuestBanner({ quest }) {
  const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

  const buttonCSS = {
    backgroundColor: quest.category.primary_color,
    color: "#FFF",
    border: "none",
  };
  const buttonHoverCSS = {
    backgroundColor: "#FFF",
    color: quest.category.primary_color,
    border: `1px solid ${quest.category.primary_color}`,
  };

  const bannerCSS = {
    backgroundColor: quest.category.primary_color,
  };

  return (
    <div className="banner">
      <div className="bannerImage"></div>
      <div style={bannerCSS} className="bannerColorBlock"></div>

      <div className="QuestBannerContent">
        <div className="QuestBannerContentLeft">
          <h1>{quest.title}</h1>
          <h2>{quest.subtitle}</h2>
          <p
            dangerouslySetInnerHTML={{
              __html: quest.short_description_rendered,
            }}
          />

          <div className="QuestBannerButtons">
            <a href={`/booking/q${quest.id}`}>
              <button
                style={buttonCSS}
                onMouseOver={(e) => {
                  Object.assign(e.target.style, buttonHoverCSS);
                }}
                onMouseOut={(e) => {
                  Object.assign(e.target.style, buttonCSS);
                }}
              >
                Book Now
              </button>
            </a>

            {/* <a href="/reviews">
                                <button style={buttonCSS} onMouseOver={(e) => {
                                        Object.assign(e.target.style, buttonHoverCSS);
                                    }} onMouseOut={(e) => {
                                        Object.assign(e.target.style, buttonCSS);
                                    }}>
                                        Reviews
                                </button>
                            </a> */}
          </div>
        </div>
        <div className="QuestBannerContentRight">
          {quest.video ? (
            <div className="VideoContainer">
              <iframe
                src={quest.video}
                title="YouTube video player"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          ) : (
            <img src={`${MEDIA}${quest.card_image}`} alt={quest.title} />
          )}
        </div>
      </div>
      <div className="QuestInfoSeeAll">
        <div className="QuestInfoSeeAllContent">
          <a href={`/quests/${quest.category.title.toLowerCase()}`}>
            <div className="QuestInfoSeeAllText">
              See All {quest.category.title}s
              <div style={{ fontSize: "26px", fontWeight: "300" }}>
                &nbsp;&nbsp;→
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default QuestBanner;
