import React from "react";

import { numToWord, toTitleCase } from "../../../tools";

function QuestionsSummary({sessionData, handleNavClick}) {
    const questionEvents = sessionData?.events?.filter(event => event.event_type === "question found");
    const answers = sessionData?.events?.filter(event => event.event_type === "final answers submitted")[0]?.event_data?.answers;

    // console.log(questionEvents);
    // console.log(answers);

    const renderQuestions = () => {
        if (questionEvents && answers) {
            return questionEvents.map((questionEvent, index) => {
                const answeredCorrectly = answers.includes(questionEvent.event_data.correct_answer)
                const questionOptions = [questionEvent.event_data.option_1, questionEvent.event_data.option_2, questionEvent.event_data.option_3, questionEvent.event_data.option_4];
                const answerGiven = answers.filter(answer => questionOptions.includes(answer));
                return (
                    <div className="OutroQuestion" key={index}>
                        <div className={answeredCorrectly ? "OutroQuestionIconContainer OutroQuestionIconContainerCorrect" : "OutroQuestionIconContainer OutroQuestionIconContainerIncorrect"}>
                            <h3>{answeredCorrectly ? "\u2713" : "\u2717"}</h3>
                        </div>

                        <div className="OutroQuestionTextContainer">
                            <h6>Question {toTitleCase(numToWord(index + 1))}</h6>
                            {/* <br/> */}
                            <h6>{questionEvent.event_data.question}?</h6>
                            {/* <br/> */}
                            <h6>Your Answer: "{answerGiven}"</h6>
                        </div>
                    </div>
                )
            })
        }
    }

    return (
        <div className="OutroStepContainer">
            {/* <h3>Questions Summary</h3> */}
            {renderQuestions()}
            <div style={{justifyContent: "center"}} className="OutroTabNavButtons">
                {/* <button className="OutroTabNavButton" onClick={() => handleNavClick("back")}>Back</button> */}
                <button className="OutroTabNavButton" onClick={() => handleNavClick("next")}>Next</button>
            </div>
        </div>
    )
}

export default QuestionsSummary;