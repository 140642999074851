import React from "react";

function NotFound() {
  const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

  return (
    <div className="NotFound">
      <h1>We looked everywhere...</h1>
      <img src={`${MEDIA}/media/404.png`} alt="Page Not Found" />
    </div>
  );
}

export default NotFound;
