import React, { useEffect } from "react";

import { ardiIconAnimated } from "../../tools";

import CountDownClock from "./CountDownClock";

function CountDown({booking, quest, bookingDateTime, onComplete}) {

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();
            const timeLeft = bookingDateTime - now;

            if (timeLeft <= 0) {
                clearInterval(intervalId);
                onComplete();
            }
        }, 500);

        return () => clearInterval(intervalId);
    }, [bookingDateTime, onComplete]);


    return(
        <div className="CountDown">
            {ardiIconAnimated(quest.category.primary_color)}
            <div>
                <h1 className="CountDownText">{quest.category.title}</h1>
                <h3 className="CountDownText">{quest.title}</h3>
                <br/>
            </div>
            <div>
                <h4 className="CountDownText">Your Quest Begins In:</h4>
                <CountDownClock targetDate={bookingDateTime} />
            </div>
        </div>
    )
}

export default CountDown