import React, { useState, useEffect } from "react";

function HintsTab({prompt, playerRole, handleHintRequest, sessionData, hintRequestMessage}) {
    const [hintEvents, setHintEvents] = useState([]);
    const [buttonClicked, setButtonClicked] = useState(false);

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

    const handleClick = () => {
        handleHintRequest();
        setButtonClicked(true);
    }

    useEffect(() => {
        if (buttonClicked) {
            setButtonClicked(false);
        }
        if (sessionData?.events && prompt?.hints) {
            const hints = sessionData.events.filter(event => event.event_type === "hint requested" && prompt.hints.some(hint => hint.id === event.event_data.id));
            setHintEvents(hints);
        }
    }, [sessionData]);

    const renderHints = () => {
        if (hintEvents.length === 0) {
            return <p>You have not requested any hints for this stage. <br/> Hints from previous stages can be found in the history tab.</p>;
        }
        return hintEvents.map((hintEvent) => (
            <div className="HintsTabHintsContainerHint" key={hintEvent.id}>
                {hintEvent.event_data.image && <img src={MEDIA + hintEvent.event_data.image} alt={hintEvent.event_data.title}/>}
                {hintEvent.event_data.text_rendered ?
                    <p dangerouslySetInnerHTML={{ __html: hintEvent.event_data.text_rendered }}/>
                    :
                    <p>{hintEvent.event_data.text}</p>
                }
            </div>
        ))
    }
    // console.log(hintEvents);
    return (
        <div className="HintsTab">
            {hintRequestMessage ? 
                <p>{hintRequestMessage}</p>
            :
                <div>
                    <h4 style={{marginBottom: "8px"}}>Feeling stuck?</h4>
                    {playerRole === "captain" ? 
                    (buttonClicked ? 
                        <button>Hint requested</button> 
                        : 
                        <button onClick={handleClick}>Request a Hint</button>
                    )
                    :
                    <p>Your captain can request a hint be made visible here.</p>
                }
                    <p>Note: Using hints will effect your team's final score.</p>
                </div>
            }
            <div className="HintsTabHintsContainer">
                {renderHints()}
            </div>
        </div>
    );
}

export default HintsTab;