import React from "react";

function Terms() {

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

    const backgroundCSS = {
        backgroundImage: `url(${MEDIA}/media/patterns/large-low-opacity.svg)`,
        backgroundRepeat: "repeat, repeat",
        backgroundPosition: "cover",
    };    

    return (
        <div style={backgroundCSS} className="Terms">

            <div style={backgroundCSS} className="TermsContent">
                <h1>Terms of Service</h1>
                <p>Last Edited: April 19, 2024</p>
                <p>ARDI GLOBAL LLC, a US company, operates the following websites:</p>
                <ul>
                <li><a href="https://ardi.one/homequest/" target="_blank" rel="noreferrer noopener">https://ardi.one/homequest/</a></li>
                <li><a href="https://homequest.se/" target="_blank" rel="noreferrer noopener">https://homequest.se/</a></li>
                <li><a href="https://ardi.one/officequest/" target="_blank" rel="noreferrer noopener">https://ardi.one/officequest/</a></li>
                <li><a href="https://officequest.se/" target="_blank" rel="noreferrer noopener">https://officequest.se/</a></li>
                <li><a href="https://ardi.one/cityquest/" target="_blank" rel="noreferrer noopener">https://ardi.one/cityquest/</a></li>
                <li><a href="https://cityquest.se/" target="_blank" rel="noreferrer noopener">https://cityquest.se/</a></li>
                <li><a href="https://ardi.one/touristquest/" target="_blank" rel="noreferrer noopener">https://ardi.one/touristquest/</a></li>
                <li><a href="https://ardi.one/schoolquest/" target="_blank" rel="noreferrer noopener">https://ardi.one/schoolquest/</a></li>
                <li><a href="https://ardi.one/paperquest/" target="_blank" rel="noreferrer noopener">https://ardi.one/paperquest/</a></li>
                <li><a href="https://ardi.one/puzzlequest/" target="_blank" rel="noreferrer noopener">https://ardi.one/puzzlequest/</a></li>
                </ul>
                <p>which are a part of the common service <a href="https://ardi.one/" target="_blank" rel="noreferrer noopener">https://ardi.one/</a>. Throughout the site, the terms "us", "we", "our" or "ARDI" refer to ARDI GLOBAL LLC.</p>
                <p>The terms and conditions are provided for the purpose of enabling customers to place orders online. By placing an order the customer acknowledges his/ her complete acceptance of the terms and conditions.</p>

                <h2>Prices</h2>
                <h3>HomeQuest</h3>
                <p>The prices listed by ARDI on ARDI's website (<a href="https://ardi.one/homequest/" target="_blank" rel="noreferrer noopener">https://ardi.one/homequest/</a>) include one game session with a maximum of 6 people and a maximum of 150 minutes per session. The prices are shown in USD. ARDI reserves the right to modify these prices at any time. Services will be billed according to the rates in effect at the moment the order is placed.</p>
                <h3>OfficeQuest</h3>
                <p>The prices listed by ARDI on ARDI's website (<a href="https://ardi.one/officequest/" target="_blank" rel="noreferrer noopener">https://ardi.one/officequest/</a>) include one game session with a maximum of 6 people and a maximum of 120 minutes per session. The prices are shown in USD. ARDI reserves the right to modify these prices at any time. Services will be billed according to the rates in effect at the moment the order is placed.</p>
                <h3>CityQuest</h3>
                <p>The prices listed by ARDI on ARDI's website (<a href="https://ardi.one/cityquest/" target="_blank" rel="noreferrer noopener">https://ardi.one/cityquest/</a>) include one game session with a maximum of 150 minutes per session and person. A minimum number of players in one game session is 2 people. The prices are shown in USD. ARDI reserves the right to modify these prices at any time. Services will be billed according to the rates in effect at the moment the order is placed.</p>
                <h3>TouristQuest</h3>
                <p>The prices listed by ARDI on ARDI's website (<a href="https://ardi.one/touristquest/" target="_blank" rel="noreferrer noopener">https://ardi.one/touristquest/</a>) include one game session with a maximum of 150 minutes per session and person. A minimum number of players in one game session is 2 people. The prices are shown in USD. ARDI reserves the right to modify these prices at any time. Services will be billed according to the rates in effect at the moment the order is placed.</p>
                <h3>SchoolQuest</h3>
                <p>The prices listed by ARDI on ARDI's website (<a href="https://ardi.one/schoolquest/" target="_blank" rel="noreferrer noopener">https://ardi.one/schoolquest/</a>) include one game session with a maximum of 150 minutes per session and person. A minimum number of players in one game session is 2 people. The prices are shown in USD. ARDI reserves the right to modify these prices at any time. Services will be billed according to the rates in effect at the moment the order is placed.</p>
                <h3>PaperQuest</h3>
                <p>The prices listed by ARDI on ARDI's website (<a href="https://ardi.one/paperquest/" target="_blank" rel="noreferrer noopener">https://ardi.one/paperquest/</a>) are for downloadable and printable games. The prices are shown in USD. ARDI reserves the right to modify these prices at any time. Games will be billed according to the rates in effect at the moment the order is placed.</p>
                <h3>PuzzleQuest</h3>
                <p>The prices listed by ARDI on ARDI's website (<a href="https://ardi.one/puzzlequest/" target="_blank" rel="noreferrer noopener">https://ardi.one/puzzlequest/</a>) are for a 3-month subscription model where customers receive a puzzle box. The prices are shown in USD. ARDI reserves the right to modify these prices at any time. Subscriptions will be billed according to the rates in effect at the moment the order is placed.</p>

                <h2>Gift cards</h2>
                <p>The gift card is a PDF document that you receive via e-mail after you have made a payment according to instructions.</p>
                <p>The gift card is valid for 1 year from the date of purchase and contains a promo code that can be linked to a specific game with a specific number of players or be worth a specific amount.</p>

                <h2>Reservations</h2>
                <h3>HomeQuest, OfficeQuest, SchoolQuest</h3>
                <p>All reservations placed online are processed in real time and are subject to availability. The reservation will not be considered complete until a confirmation email is sent from ARDI. The game session can be activated at any time by following the link provided in the confirmation email, but not later than 365 days after the reservation was made.</p>
                <h3>CityQuest, TouristQuest</h3>
                <p>All reservations placed online are processed in real time and are subject to availability. The reservation will not be considered final until a confirmation email is sent from ARDI.</p>
                <p>The game session with special date and time can be activated by following the web link provided in the confirmation email upon arrival.</p>
                <p>The game sessions that do not require a special date and time can be activated at any time by following the link provided in the confirmation email, but not later than 365 days after the reservation was made.</p>
                <h3>PaperQuest</h3>
                <p>After the payment is processed, the customer will receive a download link for the game. The download link will be active for 30 days.</p>
                <h3>PuzzleQuest</h3>
                <p>After the payment is processed, the customer will receive a confirmation email about their subscription. The puzzles will be shipped to the provided address every month for 3 months.</p>

                <h2>Payment</h2>
                <p>ARDI will only accept payments by credit or debit cards via Stripe. Business customers can also choose invoice as payment method. Payment terms 14 days. Contact us if you need longer payment terms.</p>
                <p>We do not keep any customer credit card information on our database.</p>

                <h2>Cancellation policy</h2>
                <h3>HomeQuest, OfficeQuest, SchoolQuest</h3>
                <p>Cancellation with a full refund can be made via the phone or email within 14 days after the reservation has been made if the game session was not activated. The game will be canceled automatically with no refund if the game session has not been activated during 365 days after the reservation has been made.</p>
                <h3>Reservations with discount code</h3>
                <p>Cancellation of an activity that was booked with a discount code is refunded only to 50%. Cancellation can be made via the phone or email within 14 days after the reservation has been made if the game session was not activated.</p>
                <p>No refunds will be given for no-shows or late arrivals. Any fees associated with rescheduling or cancellations outside of the allowed windows will be communicated at the time of the request.</p>
                <p>ARDI reserves the right to cancel or modify any game session due to unforeseen circumstances, with appropriate refunds or rescheduling offered.</p>
                <h3>CityQuest, TouristQuest</h3>
                <p>Game sessions with specific date and time can be canceled with a full refund via the phone or email within 14 days after the reservation has been made, but not later than 24 hours before the game session starts and if the game session was not activated. The game will be canceled automatically with no refund if the buyer does not arrive at the game's starting point (stated in the confirmation email) no later than 15 minutes after the date and time stated at the time of booking.</p>
                <p>Game sessions with specific date and time can be rescheduled free of charge by phone or e-mail no later than 24 hours before the game session starts.</p>
                <p>Game sessions that do not require a special date and time can be canceled with a full refund via the phone or email within 14 days after the reservation has been made if the game session was not activated. The game will be canceled automatically with no refund if the game session has not been activated during 365 days after the reservation has been made.</p>
                <h3>Reservations with discount code</h3>
                <p>Cancellation of an activity that was booked with a discount code is refunded only to 50%. Cancellation can be made via the phone or email within 14 days after the reservation has been made, but not later than 24 hours before the game session starts and if the game session was not activated.</p>
                <p>No refunds will be given for no-shows or late arrivals. Any fees associated with rescheduling or cancellations outside of the allowed windows will be communicated at the time of the request.</p>
                <p>ARDI reserves the right to cancel or modify any game session due to unforeseen circumstances, with appropriate refunds or rescheduling offered.</p>
                <h3>Weather conditions</h3>
                <p>Similar rules apply to rescheduling the game sessions with specific date and time due to adverse weather conditions: up to 24 hours before the game session starts. However, in case the weather has changed drastically (heavy rain or similar) upon arrival and you will not be able to play, you have a right to reschedule your game session free of charge.</p>
                <h3>PaperQuest</h3>
                <p>All sales are final. No refunds will be provided.</p>
                <h3>PuzzleQuest</h3>
                <p>Subscriptions can be canceled anytime. If a cancellation occurs mid-month, the customer will receive the puzzle for that month but will not be charged for the following months. No refunds will be provided for the months where the puzzle has already been shipped.</p>

                <h2>Regulations</h2>
                <p>ARDI declines all responsibility for any damages to objects belonging to customers during the time of the game.</p>

                <h2>Intellectual Property</h2>
                <p>All content on ARDI's websites and within the game sessions, including but not limited to text, software, scripts, graphics, photos, sounds, music, videos, interactive features and the like are owned by or licensed to ARDI. Any unauthorized use, including reproduction, modification, distribution, or republication, without the prior written consent of ARDI, is strictly prohibited.</p>

                <h2>Conduct & Safety</h2>
                <p>It is strictly prohibited to take pictures, film, or record any documents, clues, or other game materials that can reveal the nature of the game. But you are very welcome to take photos of how you play or your game process.</p>
                <p>In addition, it is forbidden to reproduce texts, logos, images, or any other element which you will see during the game, or emails sent by ARDI.</p>
                <p>All participants must follow all safety guidelines and exercise caution and common sense during the game sessions. Any illegal, harmful, or offensive behavior is strictly prohibited. ARDI reserves the right to remove any participant engaging in disruptive or dangerous behavior.</p>

                <h2>Personal Data in accordance with applicable US laws</h2>
                <p>When making a booking at ARDI you can feel safe in knowing that we never will divulge these data to third parties, except upon request by a judicial authority or other administrative authority empowered by law to request the communication of this information.</p>
                <p>Please see our Privacy Policy for detailed information on data collection, use, storage, and protection measures. This includes data retention periods and how users can access, modify, or delete their personal data.</p>
                <p>We use third-party payment processors for secure credit card transactions. We do not retain any customer credit card information on our servers.</p>

                <h2>Waiver of Liability</h2>
                <p>As a participant of a game at ARDI you must agree to and acknowledge the following:</p>
                <ul>
                <li>I agree to obey and follow all instructions, rules, and warnings given by ARDI at all times during the game session.</li>
                <li>I recognize that there are certain inherent risks associated with the activities, both known and unknown, and I assume full responsibility for personal injury to myself and (if applicable) my family members and further release and discharge ARDI for injury, loss, death or damage arising out of my or my family's actions during the game session whether caused by the fault of myself, my family, ARDI or any third parties. This waiver applies regardless of the form of action, whether in contract, tort (including negligence), strict liability or otherwise. By agreeing to this waiver, I acknowledge that I am giving up substantial legal rights, including the right to sue.</li>
                <li>I consent that the contact information provided is true and accurate, and may be stored indefinitely by ARDI for legal and operational uses.</li>
                </ul>

                <h2>Governing Law & Dispute Resolution</h2>
                <p>These terms and the provision of our services are governed by and construed in accordance with the laws of the State of Illinois without regard to its conflict of law provisions.</p>
                <p>Any dispute relating in any way to your use of ARDI's services shall first be resolved through informal discussions. If a dispute is not resolved through informal discussions, it shall be resolved by binding arbitration administered by the American Arbitration Association in accordance with its Commercial Arbitration Rules. The seat of arbitration shall be Chicago, Illinois. Judgment on the award rendered by the arbitrator(s) may be entered in any court having jurisdiction thereof.</p>

                <h2>Contact Us</h2>
                <p>If you have any questions about our Terms and Conditions, please contact us:</p>
                <p>Email: <a href="mailto:hello@ardi.one">hello@ardi.one</a></p>
            </div>
        </div>
    );
}

export default Terms