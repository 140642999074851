import React, { useState, useEffect } from "react";

function Banner() {
  const [images, setImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchImages = async () => {
      const endpoint = `${API_BASE_URL}/home-page-banner-images/`;
      try {
        const response = await fetch(endpoint);
        const data = await response.json();
        // console.log("banner-images", data)
        setImages(data);
      } catch (error) {
        // console.error("Error fetching images:", error);
      }
      // console.log(endpoint);
    };
    fetchImages();
  }, [API_BASE_URL]);

  useEffect(() => {
    if (images.length > 0) {
      const interval = setInterval(() => {
        if (currentImageIndex < images.length - 1) {
          setCurrentImageIndex(currentImageIndex + 1);
        } else {
          setCurrentImageIndex(0);
        }
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [images, currentImageIndex]);

  const renderFadingImages = () => {
    if (images.length > 0) {
      return images.map((image, index) => {
        return (
          // <div
          //     className={`BannerImage ${currentImageIndex === index ? 'Show' : ''}`}
          //     style={{ backgroundImage: `url(${MEDIA}${image.image})` }}
          //     key={index}
          // />
          <div
            className={`BannerImage ${
              currentImageIndex === index ? "Show" : ""
            }`}
            key={index}
          >
            <img src={`${MEDIA}${image.image}`} alt={`Banner ${index}`} />
          </div>
        );
      });
    }
  };

  return (
    <div className="banner">
      <div className="bannerImage"></div>
      <div className="bannerColorBlock"></div>

      <div className="bannerContent">
        <div className="bannerContentLeft">
          <h1>
            Platform For
            <br />
            Top-Class Online &<br />
            Offline Immersive <br />
            Adventure Games
          </h1>
          <div className="bannerButtons">
            <a href="/booking">
              <button>Book Now</button>
            </a>
            {/* <a href="/reviews"><button href="/reviews">Reviews</button></a> */}
          </div>
        </div>
        <div className="bannerContentRight">{renderFadingImages()}</div>
      </div>
    </div>
  );
}

export default Banner;
