import React from "react";

import { calculateDuration } from "./QuestClientTools";

function EndStatePopup({questEndState, setShowEndPopup, setActiveTab, quest, sessionData}) {

    // console.log(quest)

    const renderContent = () => {
        switch (questEndState) {
            case "success":
                return(
                    <div className="EndStatePopupContent">
                        {/* <h3>Congratulations!</h3>
                        <h4>You are at the end of the story!</h4> */}
                        <p dangerouslySetInnerHTML={{ __html: quest.win_message_rendered }}/>
                        <br/>
                        <h4 style={{color: "#0077FF"}}>"{quest.title}"</h4>
                        <br/>
                        <h4 style={{color: "#0077FF"}}>{calculateDuration(sessionData)} min</h4>
                        {/* <br/>
                        <p>We just need to answer the questions now.</p> */}
                        <br/>
                    </div>
                    )
            case "failure":
                return(
                    <div className="EndStatePopupContent">
                        <p dangerouslySetInnerHTML={{ __html: quest.fail_message_rendered }}/>
                        <br/>
                    </div>
                )
            case "timeUp":
                return(
                        <div className="EndStatePopupContent">
                            <h3>Time is up!</h3>
                            <br/>
                            <p>You cannot progress further through the story but you can still answer the questions.</p>
                        </div>
                    )
            default:
                return <div>Error loading content</div>
        }
    }

    const handleClick = () => {
        setShowEndPopup(false);
        setActiveTab("questions");
    }


    return (
        <div className="EndStatePopup">
            <div className="EndStatePopupContainer">
                {renderContent()}
                <button onClick={handleClick}>GO TO QUESTIONS</button>
            </div>
        </div>
    )
}

export default EndStatePopup