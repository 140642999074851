import React, { useState, useEffect } from "react";

import { ardiIcon } from "../../../tools";

function LeaderBoard({sessionData, handleNavClick, quest}) {
    const [leaderboardRows, setLeaderboardRows] = useState([]);

    useEffect(() => {
        const fetchLeaderboard = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/leaderboard/${sessionData.quest}`);
            try {
                const data = await response.json();
                setLeaderboardRows(data);
                console.log(data);
            } catch (err) {
                // console.log(err);
            }
        }
        fetchLeaderboard();
        }, [sessionData.quest]);

    useEffect(() => {
        const highlightedRow = document.getElementById('LeaderboardTableHighlightRow');
        if (highlightedRow) {
            highlightedRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [leaderboardRows]);
    

    
    const renderLeaderboardRows = () => {
        if (!leaderboardRows) return null;
        return leaderboardRows.map((row, index) => {
            return (
                <tr id={row.session_id === sessionData.id ? "LeaderboardTableHighlightRow" : ""} key={index}>
                    <td>{row.team_name ? row.team_name.toString().toUpperCase() : ''}</td>
                    <td>{row.number_of_players}</td>
                    <td>{row.time_taken} MIN</td>
                    <td>{row.score}</td>
                </tr>
            )
        })
    }


    return (
        <div className="OutroStepContainer">
            <div>
                {ardiIcon("#F6511A","108","calc(4vw + 40px)","0 0 27 35")}
                <h3>Leaderboard</h3>
            </div>
            <div className="LeaderboardTableContainer">
                <table className="LeaderboardTable" border={1}>
                    <thead>
                        <tr>
                            <th>TEAM NAME</th>
                            <th>USER COUNT</th>
                            <th>TIME TAKEN</th>
                            <th>SCORE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leaderboardRows && renderLeaderboardRows()}
                    </tbody>
                </table>
            </div>
            <div className="OutroTabNavButtons">
                <button className="OutroTabNavButton" onClick={() => handleNavClick("back")}>Back</button>
                <button className="OutroTabNavButton" onClick={() => handleNavClick("next")}>Next</button>
            </div>
        </div>
    )
}

export default LeaderBoard;