import React, { useState, useEffect } from "react";
import BookingStepOne from "./BookingStepOne";
import BookingStepTwo from "./BookingStepTwo";
import BookingConfirmation from "./BookingConfirmation";
import StripeCheckout from "./StripeCheckout";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useParams } from "react-router-dom";

function Booking() {

    const paramId = useParams().id ?? "";
    let preselectedQuestId = "";
    let preselectedCategoryId = "";

    if (paramId) {
        if (paramId.startsWith("q")) {
            preselectedQuestId = paramId.slice(1);
        } else if (paramId.startsWith("c")) {
            preselectedCategoryId = paramId.slice(1);
        }
    }

    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState("");
    const [step, setStep] = useState(1);
    const [quests, setQuests] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedQuest, setSelectedQuest] = useState([]);
    const [showPaymentElement, setShowPaymentElement] = useState(false);
    const [csrfToken, setCsrfToken] = useState("");

    const [filteredQuests, setFilteredQuests] = useState([]);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

    const backgroundCSS = {
        backgroundImage: `url(${MEDIA}/media/patterns/medium-low-opacity.svg)`,
        backgroundRepeat: "no-repeat, repeat",
        backgroundSize: "90vw, 80vh",
        backgroundPosition: "center",
    };

    const [formData, setFormData] = useState({
        price: null,
        category: preselectedCategoryId,
        language: "English",
        quest: preselectedQuestId,
        players: 2,
        date: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        mailingList: true,
        tos: false,
        promo: "",
        priceWithPromo: null,
        stripePaymentId: "",
        howDidYouHearAboutUs: "",
    })

    useEffect(() => {
        const fetchCsrfToken = async () => {
            const response = await fetch(`${API_BASE_URL}/get-csrf-token/`);
            const { csrfToken } = await response.json();
            setCsrfToken(csrfToken);
        };
        fetchCsrfToken();
    }, [API_BASE_URL]);

    useEffect(() => {
        const fetchStripeKey = async () => {
            const response = await fetch(`${API_BASE_URL}/stripe-key`);
            const { publishableKey } = await response.json();
            setStripePromise(loadStripe(publishableKey));
        };
        fetchStripeKey();
    }, [API_BASE_URL]);

    useEffect(() => {
        if(step === 2) {
            fetch(`${API_BASE_URL}/create-payment-intent/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken
                },
                body: JSON.stringify({
                    amount: formData.price,
                    currency: selectedQuest.price_per_unit.currency,
                    formData: formData
                }),
            }).then(async (result) => {
                const { clientSecret, id } = await result.json();
                setClientSecret(clientSecret);
                setFormData({ ...formData, stripePaymentId: id });
                // console.log(csrfToken);
            });
        } else {
            setClientSecret("");
        }
        }, [step]);

    useEffect(() => {
        const fetchQuests = async () => {
            try {
                const questsRes = await fetch(`${API_BASE_URL}/bookable-quests`);
                if (!questsRes.ok) {
                    throw new Error(`HTTP error! Status: ${questsRes.status}`);
                }
                const questsData = await questsRes.json();
                setQuests(questsData)
            } catch (err) {
                console.error("Fetch error:", err);
            }
        }
        fetchQuests();

    }, []);

    // set price and players based on selected quest on first load
    useEffect(() => {
        const findQuest = quests.find(quest => quest.id.toString() === formData.quest);
        if (findQuest) {
            const price = findQuest.price_per_unit.amount * findQuest.min_players_per_team
            const minPlayers = findQuest.min_players_per_team;
            setFormData({ ...formData, price: price, players: minPlayers });
        }
    }, [quests]);
    // -------------------------------------------------------------

    // On selected quest change, set price and players
    useEffect(() => {
        if (selectedQuest && selectedQuest.price_per_unit) {
            const price = selectedQuest.price_per_unit.amount * selectedQuest.min_players_per_team
            const minPlayers = selectedQuest.min_players_per_team;
            setFormData({ ...formData, price: price, players: minPlayers });
        }
    }, [selectedQuest]);
    // -------------------------------------------------------------

    // On selected category change, filter quests, set price and players
    useEffect(() => {
        if (formData.category) {
            const filtered = quests.filter(quest => quest.language === formData.language && quest.category.id.toString() === formData.category);
            if (filtered.length > 0) {
                setFilteredQuests(filtered);
                const price = filtered[0].price_per_unit.amount * filtered[0].min_players_per_team
                const minPlayers = filtered[0].min_players_per_team;
                setFormData({ ...formData, price: price, players: minPlayers });
            }
        }
    }, [formData.category, formData.language]);

    useEffect(() => { 
        const gatherCategories = () => {
            if (quests) {
                const categories = quests.map((quest) => quest.category);
                let found = [];
                let titles = [];
                for (let i = 0; i < categories.length; i++) {
                    if (!titles.includes(categories[i].title)) {
                        found.push(categories[i]);
                        titles.push(categories[i].title);
                    }
                }
                return found;
            }
            return [];
        };
        const uniqueCategories = gatherCategories();
        setCategories(uniqueCategories);
        // console.log(formData);
    }, [quests]);


    // custom styling for stripe payment element
    const appearance = {
        theme: 'flat',
        variables: {
          fontFamily: 'poppins, sans-serif',
          fontWeightNormal: '600',
          borderRadius: '25px',
          colorBackground: '#FFF',
          colorPrimary: '#FF8800',
          accessibleColorOnColorPrimary: '#1A1B25',
          colorText: '#FF8800', // It's this one
          colorTextSecondary: '#0077FF',
          colorTextPlaceholder: '#8eb7e6',
          spacingUnit: '3px',
          
        },
        rules: {
          '.Input': {
            backgroundColor: '#FFF',
            width: '1000px',
          },
          '.Label': {
            color: '#FFF',
            marginBottom: '10px',
            marginTop: '20px',
            fontWeight: '800',
            fontSize: '14px',
          }
        },
      };

    if(step === 1) {
        return(
            <div style={backgroundCSS} className="Booking">
                <div className="booking-container">
                <h1>Let's Book it!</h1>
                <BookingStepOne 
                    selectedQuest={selectedQuest}
                    setSelectedQuest={setSelectedQuest}
                    setStep={setStep}
                    formData={formData}
                    setFormData={setFormData}
                    quests={quests}
                    categories={categories}
                    filteredQuests={filteredQuests}
                    setFilteredQuests={setFilteredQuests}
                />
                </div>
            </div>
        )
    }
    if(step === 2) {
        return(
            <div style={backgroundCSS} className="Booking">
                <div className="booking-container">
                <h1>Almost There!</h1>
                <div  className="BookingStepRow">
                    <button  onClick={() => setStep(1)} id="BookingStepBackButton">&lt; Back</button>
                </div>

                <BookingStepTwo 
                    formData={formData} 
                    setFormData={setFormData} 
                    showPaymentElement={showPaymentElement}
                    setShowPaymentElement={setShowPaymentElement}
                    clientSecret={clientSecret} 
                    setClientSecret={setClientSecret}
                    selectedQuest={selectedQuest}
                    csrfToken={csrfToken}
                />

                {showPaymentElement ? (
                    stripePromise && clientSecret ? (
                        <Elements stripe={ stripePromise } options={{ clientSecret, appearance }}>
                            <StripeCheckout setStep={setStep} formData={formData} setFormData={setFormData}/>
                        </Elements>
                    ) : <p>Loading...</p>
                )
                : null}
</div>
            </div>
        )
    }
    if(step === 3) {
        return(
            <div style={backgroundCSS} className="Booking">
                <div className="booking-container">
                <BookingConfirmation
                formData={formData}
                csrfToken={csrfToken}
                />
                </div>
            </div>
        )
    }
}

export default Booking;