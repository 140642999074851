import React from "react";

function ReviewCard({ review }) {
  const stars = "⭐".repeat(review.rating);

  const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

  const quotes = `${MEDIA}/media/Clipart-Key-2271265.png`;

  const imageLink = review.image
    ? `${MEDIA}${review.image}`
    : `${MEDIA}${review.quest.card_image}`;

  return (
    <div className="ReviewCard">
      <div className="WhatCustomersSayLower">
        <img src={imageLink} alt="What Customers Say" />
        <div className="WhatCustomersSayLowerText">
          <img src={quotes} alt="" />
          <h4>{review.title}</h4>
          <p>{review.content}</p>
          <br />
          <br />
          <strong>-{review.reviewer}</strong>
          <br />
          {stars}
        </div>
      </div>
    </div>
  );
}

export default ReviewCard;
