import { pl } from "date-fns/locale";
import React, { useState, useEffect } from "react";

function FullTeamOrRejoin({ token, setPlayerInTeam, setPlayerName, setPlayerRole }) {
    const [playerNameInput, setPlayerNameInput] = useState("");
    const [newtoken, setNewToken] = useState("");
    const [response, setRespose] = useState('');

    const checkPlayerInTeam = async () => {
        try {
            const cleanedPlayerName = playerNameInput.trim().toLowerCase();
            const response = await fetch(
                `${process.env.REACT_APP_API_BASE_URL}/check-player-in-team?token=${encodeURIComponent(token)}&name=${encodeURIComponent(cleanedPlayerName)}`, 
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            );
            const data = await response.json();
            // console.log(data);
            setRespose(data);
        } catch (err) {
            // console.log(err);
        }
    }

    useEffect(() => {
        if(response) {
            if (response['valid']) {
                setPlayerInTeam(true);
                setPlayerName(playerNameInput);
                if (response['player']['role'] !== "False") {
                    setPlayerRole(response['player']['role']);
                }
            }
        }
    }, [response]);

    return (
        <div className="FullTeamOrRejoin">
            <h2>This Team is Full</h2>
            <h5>If you were already part of this team and need to rejoin,<br/>enter your player name below:</h5>
            {response && <p>{response['message']}</p>}
            <input type="text" value={playerNameInput} onChange={(e) => setPlayerNameInput(e.target.value)} placeholder="Enter Player Name" />
            <button onClick={checkPlayerInTeam}>Rejoin</button>
            <br/><br/>
            <h5>Otherwise you can try a different link,<br/>or enter a join token here:</h5>
            <input type="text" value={newtoken} onChange={(e) => setNewToken(e.target.value)} placeholder="Enter Join Token" />
            <a href={`/play/${newtoken}`}>
                <button>Join</button>
            </a>
        </div>
    );
}

export default FullTeamOrRejoin