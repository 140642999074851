import { pl } from "date-fns/locale";
import React, { useState, useEffect } from "react";

function JoinQuest({ token, setPlayerInTeam, setPlayerName, setPlayerRole, playerName, joinAsNewPlayer, setJoinAsNewPlayer }) {
    const [playerNameInput, setPlayerNameInput] = useState("");
    const [response, setRespose] = useState('');

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const checkPlayerInTeam = async () => {
        try {
            const cleanedPlayerName = playerNameInput.trim().toLowerCase();
            const response = await fetch(
                `${API_BASE_URL}/check-player-in-team?token=${encodeURIComponent(token)}&name=${encodeURIComponent(cleanedPlayerName)}`, 
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    }
                }
            );
            const data = await response.json();
            // console.log(data);
            setPlayerRole(data['player']['role']);
            setRespose(data);
        } catch (err) {
            // console.log(err);
        }
    }

    useEffect(() => {
        if(response) {
            if (response['valid']) {
                setPlayerInTeam(true);
                setPlayerName(playerNameInput);
                if (response['player']['role'] !== "False") {
                    setPlayerRole(response['player']['role']);
                }
            }
        }
    }, [response]);


    return (
        <div className="JoinQuest">
            <h2>Welcome!</h2>
            <h5 className="question-text">Are you new to this quest?</h5>
            <button onClick={() => setJoinAsNewPlayer(true)}>Join As New Player</button>
            <h5 className="input-question">If you were already part of this team and need to rejoin,<br/>enter your player name below:</h5>
            {response && <p>{response['message']}</p>}
            <input type="text" value={playerNameInput} onChange={(e) => setPlayerNameInput(e.target.value)} placeholder="Enter Player Name" />
            <button onClick={checkPlayerInTeam}>Rejoin</button>
        </div>

    )
}

export default JoinQuest