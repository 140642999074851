import React, { useState, useEffect } from "react";
import { ardiIconAnimated } from "../../tools";

import RulesAndBriefing from "./RulesAndBriefing";

function WaitForCaptain({role, token, quest, setSessionData, csrfToken}) {
    const [startClicked, setStartClicked] = useState(false);
    const [briefingCompleted, setBriefingCompleted] = useState(false);

    const postStartQuest = async (token) => {
        try {
            const questDeadlineMillis = Date.now() + parseInt(quest.duration, 10) * 60 * 1000;
            const questDeadline = new Date(questDeadlineMillis).toISOString();
            const questStartedAt = new Date().toISOString();
    
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/quest-session/${token}/`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    "X-CSRFToken": csrfToken,
                },
                body: JSON.stringify({
                    quest_started: "True",
                    quest_started_at: questStartedAt,
                    quest_deadline: questDeadline,

                }),
            });
            const data = await response.json();
            if (response.ok) {
                // console.log("Updated session data:", data);
                setStartClicked(true);
                setSessionData(data['quest_session']);
            } else {
                // console.error(data.message);
            }
        } catch (error) {
            // console.error("Error:", error);
        }
    };

    if (!briefingCompleted) {
        return (
            <div className="RulesAndBriefing">
                <RulesAndBriefing
                    quest={quest}
                    setBriefingCompleted={setBriefingCompleted}
                />
            </div>
        );
    }

    if (role === "Captain" || role === "captain") {
        return (
            startClicked ? (
                <div className="WaitForCaptain">
                    {ardiIconAnimated("black")}
                    <h2>When your team is ready, click the button below to start the quest.</h2>
                    <br />
                    <h3>Launching...</h3>
                </div>
            ) : (
                <div className="WaitForCaptain">
                    {ardiIconAnimated("black")}
                    <h2>When your team is ready, click the button below to start the quest.</h2>
                    <br />
                    <br />
                    <button onClick={() => postStartQuest(token)} className="JoinButtons">
                        Start Quest
                    </button>
                </div>
            )
        );
    }

    if (role === "Quester" || role === "quester") {
        return (
            <div className="WaitForCaptain">
                {ardiIconAnimated("black")}
                <h2>When the whole team is ready, your Captain will start the quest.</h2>
            </div>
        );
    }
}

export default WaitForCaptain;