import React from "react";
import FAQItem from "./FAQItem";

function FAQItemDisplay({ faqs, category, categoryName }) {
  const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

  const showFaqs = () => {
    if (categoryName) {
      const filteredFaqs = faqs.filter(
        (faq) => faq.category === category.id && !faq.quest
      );
      if (filteredFaqs.length > 0) {
        return filteredFaqs.map((faq) => <FAQItem faq={faq} key={faq.id} />);
      }
    } else if (faqs) {
      const filteredFaqs = faqs.filter((faq) => !faq.quest && !faq.category);
      console.log("filteredFaqs", filteredFaqs);
      if (filteredFaqs.length > 0) {
        return filteredFaqs.map((faq) => <FAQItem faq={faq} key={faq.id} />);
      }
    } else {
      return <div>No FAQs available for this category.</div>;
    }
  };

  return (
    <div
      style={{ backgroundImage: `url(${MEDIA}/patterns/large-white.svg)` }}
      className="FAQItemDisplay"
    >
      {showFaqs()}
    </div>
  );
}

export default FAQItemDisplay;
