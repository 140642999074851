import React from "react";

function HistoryTab({sessionData}) {

    const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

    const renderHistory = () => {
        if (sessionData?.events) {
            return sessionData.events.sort((a, b) => a.id - b.id).map((event, index) => {
                if (event.event_type === "captain_as") {
                    return (
                        <div className="HistoryTabEvent" key={index}>
                            <h4>Input -&gt; "{event.event_data}"</h4>
                        </div>
                    );
                } else if (event.event_type === "new prompt reached") {
                    return (
                        <div className="HistoryTabEvent" key={index}>
                            <h4>New Stage Reached -&gt; {event.event_data.previous_prompt_answer}</h4>
                            <p dangerouslySetInnerHTML={{ __html: event.event_data.text_rendered }}/>
                        </div>
                    );
                } else if (event.event_type === "hint requested") {
                    return (
                        <div className="HistoryTabEvent" key={index}>
                            <h4>Hint Received:</h4>
                            <p>{event.event_data.text}</p>
                            {event.event_data.image && <img src={MEDIA + event.event_data.image} alt={event.event_data.title}/>}
                        </div>
                    );
                } else if (event.event_type === "answer selected") {
                    return (
                        <div className="HistoryTabEvent" key={index}>
                            <h4>Answer Selected -&gt; "{event.event_data.question}"</h4>
                            &nbsp;
                            <h5>"{event.event_data.answer}"</h5>
                        </div>
                )
                } else {
                    return null;
                }
            });
        }
        return null;
    };

    return (
        <div className="HistoryTab">
            <h4 style={{marginBottom: '8px'}}>Quest History</h4>
            <div className="HistoryTabEventContainer">
                {renderHistory()}
            </div>
        </div>
    );
}

export default HistoryTab;