import React from "react";
import { useNavigate } from "react-router-dom";

function TrendingCard({ quest, index }) {
  let themeColor;

  const navigate = useNavigate();

  const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;
  const imageLink = `${MEDIA}${quest.card_image}`;

  return (
    <div
      onClick={() => navigate(`/quests/info/${quest.id}`)}
      style={{ backgroundColor: quest.category.primary_color }}
      className="TrendingCard"
    >
      <div className="">
        <img
          alt=""
          className={`TrendingCardImage ${
            index % 2 === 0 ? "DiagonalClipA" : "DiagonalClipB"
          }`}
          src={imageLink}
        />
      </div>
      <div className="TrendingCardLower">
        <h3>{quest.title}</h3>
        <hr />
        <p
          dangerouslySetInnerHTML={{ __html: quest.short_description_rendered }}
        />
      </div>
      <button style={{ color: themeColor }}>Read More</button>
    </div>
  );
}

export default TrendingCard;
