import React from "react";


function QuestOutro({sessionData, handleNavClick, quest}) {
    return (
        <div className="OutroStepContainer">
            <p dangerouslySetInnerHTML={{ __html: quest.outro_rendered }}/>
        <div className="OutroTabNavButtons">
            <button className="OutroTabNavButton" onClick={() => handleNavClick("back")}>Back</button>
            <button className="OutroTabNavButton" onClick={() => handleNavClick("next")}>Next</button>
        </div>
    </div>
    )
}

export default QuestOutro