import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TrendingCard from "./TrendingCard";

function TrendingCarousel() {
  const [trendingQuests, setTrendingQuests] = useState([]);
  const [error, setError] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const endpoint = `${API_BASE_URL}/quests`;

  useEffect(() => {
    fetch(endpoint)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setTrendingQuests(data);
        // console.log(data);
      })
      .catch((err) => {
        console.error("Fetch error:", err);
        setError(err);
      });
  }, [endpoint]);

  const showTrendingQuests = () => {
    return trendingQuests
      .filter(
        (quest) =>
          quest.language === "en" &&
          !quest.title.includes("Delete") &&
          !quest.title.includes("Cabin") &&
          !quest.title.includes("TSSD") &&
          quest.card_image
      )
      .map((quest, index) => {
        return <TrendingCard quest={quest} index={index} key={quest.id} />;
      });
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  return <Slider {...settings}>{showTrendingQuests()}</Slider>;
}

export default TrendingCarousel;
