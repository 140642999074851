import React from "react";
import DatePicker from "react-datepicker";
import { languageCodetoVerbose } from "../../tools";

function BookingStepOneForm({formData, handleChange, checkMissingField, selectedQuest, setFormData, nextStep, categories, quests, filteredQuests}) {

        const showQuests = () => {
            return filteredQuests.map((quest) => {
            return (
            <option key={`q-${quest.id}`} value={quest.id}>
                {quest.title}
            </option>
            );
        });
        };
        const showCategories = () => {
            return categories.map((category) => {
                return (
                    <option key={`c-${category.id}`} value={category.id}>{category.title}</option>
                )
            })
        }
        const showLanguages = () => {
            if(quests.length > 0) {
                const uniqueLanguageCodes = [...new Set(quests.map((quest) => quest.language))];
                return uniqueLanguageCodes.map((code, index) => (
                    <option key={`l-${index}`} value={code}>
                        {languageCodetoVerbose(code)}
                    </option>
                ));
            } else {
                return (
                    <option>Select a Quest</option>
                )
            }
        }


    return (
            <div className="BookingStep">
                <div className="BookingStepRow">
                    <div className="BookingStepColumn">
                        <label>Category:</label>
                        <select value={formData.category} onChange={handleChange} name="category" className={`BookingStepSelect ${checkMissingField('category')}`}>
                            {showCategories()}
                        </select>
                    </div>
                    <div className="BookingStepColumn">
                        <label>Preferred Language:</label>
                        <select value={formData.language} onChange={handleChange} name="language" className={`BookingStepSelect ${checkMissingField('language')}`}>
                            {showLanguages()}
                        </select>
                    </div>
                </div>
                <div className="BookingStepRow">
                    <div className="BookingStepColumn">
                        <label>Quest:</label>
                        <select value={formData.quest} onChange={handleChange} name="quest" className={`BookingStepSelect ${checkMissingField('quest')}`}>
                            {showQuests()}
                        </select>
                    </div>
                </div>
                <div className="BookingStepRow">
                    <div  className="BookingStepColumn">
                        <label>How many players?</label>
                        <input 
                            onChange={handleChange} 
                            name="players" 
                            value={formData.players} 
                            className={`BookingStepInput ${checkMissingField('players')}`} 
                            type="number" 
                            min={selectedQuest.min_players_per_team ? selectedQuest.min_players_per_team : 1} max="1000"/>
                                <p>
                            Note: The optimal number of players per team is {selectedQuest.min_players_per_team} - {selectedQuest.max_players_per_team}. <br/>
                            If there are more than {selectedQuest.max_players_per_team} players, the system will automatically divide
                            you into teams with a maximum of {selectedQuest.max_players_per_team} players each.
                        </p>
                    </div>
                </div>
                <div className="BookingStepRow">
                    <div className="BookingStepColumn">
                        <label>Choose your date and time:</label>
                        <div className={`BookingDatePickerContainer ${checkMissingField('date')}`}>
                            <DatePicker 
                                className="BookingDatePicker" 
                                wrapperClassName="BookingDatePicker" 
                                selected={formData.date} 
                                onChange={date => setFormData({ ...formData, date })}
                                showTimeSelect
                                timeFormat="h:mm aa"
                                timeIntervals={15}
                                dateFormat="h:mm aa - MMMM d, yyyy"
                                placeholderText="Select a date and time"
                                />
                        </div>
                    </div>
                </div>
                <div className="BookingStepRow">
                    <h4 style={{textAlign: "right", marginTop: "20px"}}>Price: ${formData.price}</h4>
                </div>
                <div style={{justifyContent: "end"}} className="BookingStepRow">
                    <button onClick={nextStep} className="BookingStepButton">Next &gt;</button>
                </div>
            </div>
    )
}

export default BookingStepOneForm