import React from "react";

import { useNavigate } from "react-router-dom";

function NewsCard({ news, number }) {
  const direction = number % 2 !== 0 ? "row" : "row-reverse";

  const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

  const image_url = `${MEDIA}/${news.image}`;

  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/news-item/${news.id}`)}
      id={news.id}
      style={{ display: "flex", flexDirection: direction }}
      className="NewsCard"
    >
      <div className="NewsCardLeft">
        <h1>{news.title}</h1>
        <h4 dangerouslySetInnerHTML={{ __html: news.subtitle_rendered }} />
        <img src={image_url} alt={news.title} />
      </div>
      <div className="NewsCardRight">
        <p dangerouslySetInnerHTML={{ __html: news.preview_rendered }} />
      </div>
    </div>
  );
}

export default NewsCard;
