import React from "react";

function Creators() {
  return (
    <div className="Creators LimitWidthParent">
      <div className="CreatorsContent LimitWidthChild">
        <h1>Introducing the ARDI Creator Program</h1>
        <p>
          Your chance to showcase your storytelling prowess, earn creator
          rewards, and turn your love for your city into a rewarding venture!
        </p>
        <p>
          As a new writer, you'll start with a 30% creator reward rate until you
          sell your first 100 quests. Reach that milestone, and unlock the Tier
          2 level, skyrocketing your rate to a jaw-dropping 70%! Maintain a
          4-star rating over 6 months and create one new quest every 12 months
          to keep that sweet lifetime benefit.
        </p>
        <p>
          We're on a worldwide hunt for exceptional writers to create
          captivating CityQuests that showcase the heart and soul of their
          hometowns.
        </p>
        <p>
          Whether you hail from the charming streets of Stockholm, the vibrant
          lanes of Barcelona, or the bustling boroughs of New York, this is your
          chance to put your city on the map!
        </p>
        <p>
          Our CityQuest adventures have already taken root in Stockholm,
          Göteborg, Lund, Barcelona, Sydney, London, Nashville, San Francisco,
          New York, Dallas, and Chicago.
        </p>
        <p>But we're not stopping there!</p>
        <p>
          We're eagerly seeking passionate writers from every corner of the
          globe to join our growing community of storytellers.
        </p>
        <p>
          So, whether you're a local legend in Lima, a culture connoisseur in
          Cairo, or a history buff in Helsinki, we want to hear from you! Your
          unique perspective and love for your city are the key ingredients to
          crafting unforgettable CityQuests that will inspire and delight
          adventurers from all walks of life.
        </p>
        <p>
          Don't see your city listed? No worries! We're always excited to
          explore new destinations. If you have a knack for spinning tales and a
          deep connection to your hometown, we want you on our team!
        </p>
        <p>
          As a CityQuest writer, you'll retain ownership of your quests'
          intellectual property while granting ARDI a non-exclusive,
          royalty-free, perpetual, and irrevocable license to showcase your work
          worldwide. It's a win-win collaboration!
        </p>
        <p>
          Ready to embark on this thrilling creative journey? Take the first
          step by diving into our Creator Program Terms below. Get ready to
          transform streets into quests, landmarks into chapters, and your
          passion into unforgettable adventures!
        </p>
        <p>
          To apply, simply send an email to{" "}
          <a href="mailto:hello@ardi.one">hello@ardi.one</a> with your story
          ideas and your connection to the city you want to feature. Let's work
          together to create CityQuests that inspire, engage, and reward your
          creative spirit!
        </p>

        <h2>Creator Program Terms</h2>
        <p>
          We want to be as transparent with our writers as possible and are
          placing our terms upfront.
        </p>
        <p>
          ARDI offers a creator program to incentivize writers to create
          engaging and high-quality CityQuest content. By participating in this
          program, writers can earn creator rewards based on the sales of their
          quests.
        </p>

        <h3>Creator Reward Structure</h3>
        <h4>New Writers:</h4>
        <ul>
          <li>
            Writers who are new to the platform will start at a creator reward
            rate of 30% of the sales price for each quest sold.
          </li>
          <li>
            This rate will apply until the writer has sold a total of 100
            quests.
          </li>
        </ul>

        <h4>Experienced Writers:</h4>
        <ul>
          <li>
            Once a writer has sold a total of 100 quests, their creator reward
            rate may increase to 70% of the sales price for each quest sold.
          </li>
        </ul>

        <h3>Creator Reward Tiers and Requirements</h3>
        <h4>Tier 1: 30% creator reward rate</h4>
        <ul>
          <li>Applies to new writers until they have sold 100 quests.</li>
          <li>No additional requirements.</li>
        </ul>

        <h4>Tier 2: 70% creator reward rate</h4>
        <ul>
          <li>Applies to writers who have sold 100 or more quests.</li>
          <li>
            Requires maintaining an average rating of 4 stars or higher for
            their quests over a 6-month period.
          </li>
          <li>
            Requires consistent creation and publication of new quests, with at
            least one new quest every 12 months.
          </li>
        </ul>

        <h4>Inactivity or Poor Feedback:</h4>
        <p>
          If a writer in Tier 2 fails to meet the requirements (maintaining an
          average rating of 4 stars or higher, creating at least one new quest
          every 12 months), their creator reward rate will drop back to 30%
          until they meet the requirements again.
        </p>

        <h3>Creator Reward Payments</h3>
        <p>
          Creator rewards will be calculated monthly and paid out within 30 days
          of the end of each month. Writers will need to provide valid payment
          information to receive their creator rewards.
        </p>

        <h3>Content Guidelines and Quality Control</h3>
        <p>
          All quests created by writers must adhere to ARDI's content guidelines
          and quality standards. ARDI reserves the right to reject or remove any
          quests that do not meet these standards.
        </p>

        <h3>Intellectual Property</h3>
        <p>
          Writers retain ownership of the intellectual property for the quests
          they create. However, by participating in the creator program, writers
          grant ARDI a non-exclusive, royalty-free, perpetual, and irrevocable
          license to use, reproduce, modify, adapt, publish, translate, create
          derivative works from, distribute, and display their quests worldwide.
        </p>

        <h3>Changes to the Creator Program</h3>
        <p>
          ARDI reserves the right to modify, suspend, or terminate the creator
          program at any time, with or without notice. In the event of any
          changes, ARDI will notify participants and provide them with the
          opportunity to adjust their participation accordingly.
        </p>

        <h4>Whew!</h4>
        <p>
          If you haven’t already done so be sure to apply by, simply send an
          email to <a href="mailto:hello@ardi.one">hello@ardi.one</a> with your
          story ideas and your connection to the city you want to feature. We
          will reach out as soon as possible!
        </p>
      </div>
    </div>
  );
}

export default Creators;
