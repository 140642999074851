

export function calculateDuration(sessionData) {
    const { quest_started_at, quest_completed_at } = sessionData;

    if (!quest_started_at || !quest_completed_at) {
        return null;
    }

    const start = new Date(quest_started_at);
    const end = new Date(quest_completed_at);

    const diffInMilliseconds = end - start;
    const diffInMinutes = Math.round(diffInMilliseconds / (1000 * 60));

    return diffInMinutes;
}
