import React, { useState, useEffect } from "react";
import NewsCard from "./Sub-Components/NewsCard";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function News() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/news`)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setNews(data);
        // console.log(data);
      })
      .catch((err) => {
        console.error("Fetch error or failed to parse JSON:", err);
      });
  }, []);

  const showNews = () => {
    return news.map((news, index) => {
      return <NewsCard number={index + 1} news={news} key={news.id} />;
    });
  };

  const headLines = () => {
    return news.map((newsItem) => {
      return (
        <a
          className="LatestPostsItem"
          key={newsItem.id}
          href={`#${newsItem.id}`}
        >
          <p>{newsItem.title}</p>
        </a>
      );
    });
  };

  return (
    <div className="News LimitWidthParent">
      <div className="LimitWidthChild">
        <div className="NewsSpacer">
          <div className="NewsSpacerLeft" />
          <div className="NewsSpacerRight" />
        </div>
        <div className="NewsBanner">
          <div className="NewsBannerLeft">
            <div className="LatestPostsContainer">
              <h4>Latest Posts</h4>
              <div className="LatestPosts">{news ? headLines() : null}</div>
            </div>
          </div>
          <div className="NewsBannerRight">
            <h1>Blog and News</h1>
          </div>
        </div>

        {/* <div className="NewsFilterButtons">
                    <button>Filter</button>
                    <button>Search</button>
                    <button>Archives</button>
                    </div> */}

        <div className="NewsCardContainer">{news ? showNews() : null}</div>
      </div>
    </div>
  );
}

export default News;
