import React from "react";
import { ardiIcon } from "../../tools";

function CategoryCard({ category }) {
  const categoryPageLink = category.published
    ? `/quests/${category.title.toLowerCase()}/`
    : "#WhatKindOfQuester";

  const MEDIA = process.env.REACT_APP_MEDIA_BASE_URL;

  const imageLink = `${MEDIA}${category.card_image}`;

  // console.log(category)

  return (
    <div className="CategoryCard">
      <div className="CategoryCardTitleContainer">
        <div
          style={{ backgroundColor: category.secondary_color }}
          className="CategoryCardTitle"
        >
          {ardiIcon(category.primary_color, 20, 26)}
          {category.title}
        </div>
      </div>
      <a
        href={categoryPageLink}
        style={{ textDecoration: "none", color: "black" }}
      >
        <div
          style={{ backgroundColor: category.secondary_color }}
          className="CategoryCardContent"
        >
          <img
            className="CategoryCardImage"
            src={imageLink}
            alt={category.title}
          />
          <div className="CategoryCardContentDescription">
            <h2>{category.name}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: category.short_description_rendered,
              }}
            />
            <h2>{category.published ? "" : "Coming Soon..."}</h2>
          </div>
        </div>
      </a>
    </div>
  );
}

export default CategoryCard;
