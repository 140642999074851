import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import FAQItemDisplay from "./Sub-Components/FAQItemDisplay";

const FAQ = () => {
  const { categoryName } = useParams();
  const location = useLocation();
  const [faqs, setFaqs] = useState([]);
  const [categoryFaqs, setCategoryFaqs] = useState([]);
  const [questFaqs, setQuestFaqs] = useState([]);
  const [category, setCategory] = useState({});
  const [isQuestFaq, setIsQuestFaq] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  // Fetch general FAQs
  useEffect(() => {
    const fetchGeneralFaqs = async () => {
      try {
        const res = await fetch(`${API_BASE_URL}/faq/`);
        if (!res.ok) {
          throw new Error(`Failed to fetch: ${res.statusText}`);
        }
        const data = await res.json();
        setFaqs(data.filter((faq) => !faq.category && !faq.quest));
      } catch (error) {
        console.error("Error fetching general FAQs:", error);
      }
    };
    fetchGeneralFaqs();
  }, [API_BASE_URL]);

  // Fetch category and its FAQs
  useEffect(() => {
    const fetchCategoryData = async () => {
      if (!categoryName) return;

      try {
        // Fetch category details
        const categoryRes = await fetch(
          `${API_BASE_URL}/categories/${categoryName}`
        );
        if (!categoryRes.ok) {
          throw new Error(
            `Failed to fetch category: ${categoryRes.statusText}`
          );
        }
        const categoryData = await categoryRes.json();
        setCategory(categoryData);

        // Fetch category FAQs
        const faqRes = await fetch(
          `${API_BASE_URL}/faq/category/${categoryName}`
        );
        if (!faqRes.ok) {
          throw new Error(
            `Failed to fetch category FAQs: ${faqRes.statusText}`
          );
        }
        const faqData = await faqRes.json();
        setCategoryFaqs(faqData);
      } catch (error) {
        console.error("Error fetching category data:", error);
      }
    };

    if (categoryName) {
      fetchCategoryData();
    }
  }, [API_BASE_URL, categoryName]);

  // Check if we're showing quest FAQs and fetch them if needed
  useEffect(() => {
    const questId = new URLSearchParams(location.search).get("quest");

    const fetchQuestFaqs = async () => {
      try {
        const res = await fetch(`${API_BASE_URL}/faq/quest/${questId}`);
        if (!res.ok) {
          throw new Error(`Failed to fetch quest FAQs: ${res.statusText}`);
        }
        const data = await res.json();
        setQuestFaqs(data);
        setIsQuestFaq(true);
      } catch (error) {
        console.error("Error fetching quest FAQs:", error);
      }
    };

    if (questId) {
      fetchQuestFaqs();
    } else {
      setIsQuestFaq(false);
      setQuestFaqs([]);
    }
  }, [API_BASE_URL, location.search]);

  // Combine FAQs based on the context
  const combinedFaqs = React.useMemo(() => {
    if (isQuestFaq) {
      // For quest FAQ pages, show quest FAQs and category FAQs
      return [...questFaqs, ...categoryFaqs];
    } else if (categoryName) {
      // For category FAQ pages, show only category FAQs
      return categoryFaqs;
    } else {
      // For general FAQ page, show general FAQs
      return faqs;
    }
  }, [isQuestFaq, categoryName, questFaqs, categoryFaqs, faqs]);

  const backgroundColor = category?.primary_color || "#0077FF";
  const title = isQuestFaq
    ? "Quest Frequently Asked Questions"
    : categoryName
    ? `${categoryName} Frequently Asked Questions`
    : "Frequently Asked Questions";

  return (
    <div style={{ backgroundColor }} className="FAQ LimitWidthParent">
      <div className="FAQContainer LimitWidthChild">
        <h2>{title}</h2>
        <FAQItemDisplay
          faqs={combinedFaqs}
          category={category}
          categoryName={categoryName}
          isQuestFaq={isQuestFaq}
        />
      </div>
    </div>
  );
};

export default FAQ;
