import React, { useEffect, useState } from "react";
import ConfettiExplosion from 'react-confetti-explosion';

function BookingConfirmation({ formData, csrfToken }) {
    const [bookingConfirmed, setBookingConfirmed] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isExploding, setIsExploding] = React.useState(true);
    const [bookingRef, setBookingRef] = useState("");
    const [joinToken, setJoinToken] = useState("");

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


    useEffect(() => {
        const createBooking = async () => {
            try {
                fetch(`${API_BASE_URL}/create-booking/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-CSRFToken": csrfToken
                    },
                    body: JSON.stringify(formData),
                }).then(response => response.json())
                    .then(data => {
                        setBookingRef(data['booking_ref'])
                        setJoinToken(data['join_token'])
                        // console.log(data)
                    })
                    
                    setBookingConfirmed(true);
                } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        
        if (!bookingConfirmed) {
            createBooking();
        }
    }, []);

    if (loading) {
        return <div>Just a moment...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div style={{justifyContent: "center", alignItems: "center",}} className="BookingStep">
            <>{isExploding && <ConfettiExplosion particleCount={200} force={0.5} width={2000} colors={["#F6511A", "#0077FF", "#FFB503", "#83B80E", "#f6511a"]} />}</>
            <>{isExploding && <ConfettiExplosion particleCount={200} force={0.5} width={2000} colors={["#F6511A", "#0077FF", "#FFB503", "#83B80E", "#f6511a"]} />}</>
            <div className="BookingConfirmation">
            <h1>Booking Confirmed!</h1><br />
                Thank you for booking with us! <br /><br />
                You'll receive a confirmation email shortly. <br /><br />
                {bookingRef ? `Your booking reference is ${bookingRef}` : ""} <br />
                {joinToken ? `Your join token is ${joinToken}` : ""}
            </div>
        </div>
    );
}

export default BookingConfirmation;
