import React, { useState } from "react";

function RulesAndBriefing({quest, setBriefingCompleted}) {
    const [step, setStep] = useState(1);

    // console.log(quest);

    if (step === 1) {
        return (
            <div>
                <h1>{quest.title}</h1>
                <br/>
                <h3>Rules</h3>
                <p dangerouslySetInnerHTML={{ __html: quest.rules_rendered }} />
                <br/>
                <br/>
                <button className="JoinButtons" onClick={() => setStep(2)}>Next</button>
            </div>
        );
    }

    if (step === 2) {
        return (
            <div>
                <h1>{quest.title}</h1>
                <br/>
                <h3>Briefing</h3>
                <p dangerouslySetInnerHTML={{ __html: quest.briefing_rendered }} />
                <br/>
                <br/>
                <button className="JoinButtons" onClick={() => setBriefingCompleted(true)}>Next</button>
            </div>
        );
    }
}

export default RulesAndBriefing;