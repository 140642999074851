import React, { useState, useEffect } from "react";

import { ardiIconAnimated } from "../../../tools";

function QuestionTab({sessionData, setNewQuestion, postQuestEvent, questEndState}) {
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [foundQuestionEvents, setFoundQuestionEvents] = useState([]);
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);
    const [answersSubmitted, setAnswersSubmitted] = useState(false);

    useEffect(() => {
        setNewQuestion(false);
    }, [setNewQuestion]);
    

    const handleSelectAnswer = (question, answer) => {
        postQuestEvent({event_type: "answer selected", event_data: {question, answer}});
        const relatedQuestionEvent = foundQuestionEvents.filter(event => event.event_data.question === question);
        setSelectedAnswers([...selectedAnswers, answer]);
    }

    const handleSubmitAnswers = () => {
        postQuestEvent({event_type: "final answers submitted", event_data: {answers: selectedAnswers}});
        setAnswersSubmitted(true);
    }

    useEffect(() => {
        if (sessionData?.events) {
            const questionEvents = sessionData.events.filter(event => event.event_type === "question found");
            setFoundQuestionEvents(questionEvents);

            const answerEvents = sessionData.events.filter(event => event.event_type === "answer selected");
            setSelectedAnswers(answerEvents.map(event => event.event_data.answer));
        }
    }, [sessionData?.events])

    const renderQuestions = () => {
        if (foundQuestionEvents.length === 0) {
            return <p>Nothing to see here yet, keep investigating.</p>;
        }
        return foundQuestionEvents.sort((a, b) => a.id - b.id).map((event) => (
            <div className="QuestionTabItem" key={event.id}>
                <br/>
                <h4>{event.event_data.question}?</h4>
                <div className="QuestionTabItemOptions">
                    <p onClick={() => handleSelectAnswer(event.event_data.question, event.event_data.option_1)}
                        className={selectedAnswers.includes(event.event_data.option_1) ? "QuestionTabItemOptionSelected" : ""}
                        >{event.event_data.option_1}</p>
                    <p onClick={() => handleSelectAnswer(event.event_data.question, event.event_data.option_2)}
                        className={selectedAnswers.includes(event.event_data.option_2) ? "QuestionTabItemOptionSelected" : ""}
                        >{event.event_data.option_2}</p>
                    <p onClick={() => handleSelectAnswer(event.event_data.question, event.event_data.option_3)}
                        className={selectedAnswers.includes(event.event_data.option_3) ? "QuestionTabItemOptionSelected" : ""}
                        >{event.event_data.option_3}</p>
                    <p onClick={() => handleSelectAnswer(event.event_data.question, event.event_data.option_4)}
                        className={selectedAnswers.includes(event.event_data.option_4) ? "QuestionTabItemOptionSelected" : ""}
                        >{event.event_data.option_4}</p>
                </div>
            </div>
        ))
    }


    return(
        <div className="QuestionTab">
            <div className={showConfirmPopup ? "QuestionTabConfirmPopup" : "QuestionTabConfirmPopup Hide"}>
                <div className="QuestionTabConfirmPopupContent">
                    {ardiIconAnimated("#F6511A", 100, 100)}
                    <p className={answersSubmitted ? "Hide" : ""}>Are you sure you want to submit these answers?</p>
                    <br/>
                    <div className={answersSubmitted ? "QuestionTabConfirmPopupButtons Hide" : "QuestionTabConfirmPopupButtons"}>
                        <button onClick={() => setShowConfirmPopup(false)}>Cancel</button>
                        <button onClick={() => handleSubmitAnswers()}>Submit</button>
                    </div>
                </div>
            </div>
            <h4 style={{marginBottom: '8px'}}>Questions</h4>
            <div className="QuestionTabContent">
                {renderQuestions()}
                {questEndState && <button className="QuestionTabSubmitButton" onClick={() => {setShowConfirmPopup(true)}}>Submit Answers</button>}
            </div>
        </div>
    )
}

export default QuestionTab